<template>
    <v-container>
        <v-row class="videoArea">
            <v-col cols="12" class="mt-0 pt-0" v-show="!viewYesNo">
                <video src="/images/start/start.mp4" poster="/images/start/poster.jpg" width="100%" ref="startMovie"
                    controls playsinline disablePictureInPicture controlsList="nodownload noremoteplayback"
                    oncontextmenu="return false;"></video>
            </v-col>

            <v-col cols="12" class="mt-0 pt-0 fixarea" v-show="viewYesNo">
                <img src="/images/start/moviefix.jpg" width="100%">
            </v-col>

            <v-col cols="12" v-if="viewYesNo">
                <v-row>
                    <v-col cols="6" class="pa-2 pl-15 text-right">
                        <img src="/images/start/dounyu_yes.png" width="100%" ref="btnYes" @click='eventYes' />
                    </v-col>
                    <v-col cols="6" class="pa-2 pr-15 text-left">
                        <img src="/images/start/dounyu_no.png" width="100%" ref="btnNo" @click='eventNo' />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" class="text-center mt-15 skipArea" @click="goSkip" ref="movieSkip" v-show="!viewYesNo">
                <div class="skipText">＞＞ スキップ</div>
            </v-col>
        </v-row>

        <QrWindow v-if="dialogQrScan" :codeData="qrcodeData" @close="dialogQrScan = false" @correct="eventReadQrData"
            titleType="billing">
        </QrWindow>
        <TosWindow v-if="dialogTos" @close="eventTosOk"></TosWindow>
        <ConfWindow v-if="dialogConf" @close="eventConfOk" :text="'映像で音声が流れます。\nミュートを解除して\nお楽しみください。'"></ConfWindow>
        <NoStartWindow v-if="dialogNoStart" @close="eventNoStart"></NoStartWindow>
    </v-container>
</template>

<style scoped>
.fixarea {
    position: relative;
    width: 100%;
}

.fixarea:before {
    content: "";
    display: block;
    padding-top: 56.25%;
}

.fixarea img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.videoArea {
    margin-top: 25vh;
}

video {
    opacity: 0;
}

.startMovie {
    opacity: 0;
    animation-name: videoAnime;
    animation-duration: 3s;
    animation-delay: 4s;
    animation-fill-mode: forwards;
}

@keyframes videoAnime {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.skipArea {
    position: fixed;
    bottom: 100px;
    left: 0;
    opacity: 0;
}

.skipVisible {
    opacity: 1;
}
</style>
<script>
import QrWindow from "./components/QrWindow";
import TosWindow from "./components/TosWindow";
import ConfWindow from "./components/ConfWindow";
import NoStartWindow from "./components/NoStartWindow";

export default {
    components: {
        QrWindow,
        TosWindow,
        ConfWindow,
        NoStartWindow,
    },
    data: () => ({
        dialogTos: true,
        dialogConf: false,
        viewYesNo: false,
        dialogQrScan: false,
        dialogNoStart: false,
        qrcodeData: {},
        // videoTimer: undefined
    }),
    created: async function () {
        if (this.$store.getters.isCheckTos == true) {
            this.dialogTos = false;
            this.dialogConf = true;
        }
        this.qrcodeData = this.$store.getters.code[0];
    },
    mounted: function () {
        try {
            document.getElementsByTagName("html")[0].classList.add("noScroll")
        } catch (error) { }
    },
    destroyed: function () {
        try {
            document.getElementsByTagName("html")[0].classList.remove("noScroll")
        } catch (error) { }
        // if (this.videoTimer != undefined) {
        //     clearTimeout(this.videoTimer);
        // }
        let appDom = document.getElementById("app");
        appDom.classList.remove("startBg");

    },
    methods: {
        // 利用許諾OK
        async eventTosOk() {
            try {
                await this.$stamp.checkUseDevice();
                this.$store.commit("setCheckDevice", true);
            } catch (error) {
                this.$router.replace({ name: 'error', params: { 'errormessage': "カメラなどのデバイスの利用ができません。" } });
            }
            this.dialogTos = false;
            this.$store.commit("setCheckTos", true);
            this.dialogConf = true;
        },
        // 音声確認アラート
        eventConfOk() {
            this.dialogConf = false;
            let appDom = document.getElementById("app");
            appDom.classList.add("startBg");
            let movie = this.$refs.startMovie;
            movie.classList.add("startMovie");
            movie.muted = false
            movie.volume = 1;
            movie.play();
            // movie.pause();
            movie.addEventListener("ended", () => {
                this.viewYesNo = true;
            });
            movie.addEventListener("playing", () => {
                if (this.viewYesNo) {
                    this.viewYesNo = false;
                }
            });


            let skip = this.$refs.movieSkip;
            skip.classList.add("startMovie");

            // setTimeout(() => {
            //     // movie.play();
            //     // this.checkVideoTime();
            //     movie.classList.remove("startMovie");
            //     movie.classList.add("skipVisible");

            //     skip.classList.remove("startMovie");
            //     skip.classList.add("skipVisible");
            // }, 3000);
        },
        eventYes() {
            this.$refs.btnYes.src = "/images/start/dounyu_yes_select.png"
            setTimeout(() => {
                this.dialogQrScan = true;
            }, 500);
        },
        eventNo() {
            this.$refs.btnNo.src = "/images/start/dounyu_no_select.png"
            setTimeout(() => {
                this.dialogNoStart = true;
            }, 500);
        },
        eventNoStart(mode) {
            this.dialogNoStart = false;
            if (mode == "yes") {
                let movie = this.$refs.startMovie;
                movie.currentTime = 0;
                movie.play();
            }
        },

        eventReadQrData(data) {
            console.log("eventReadQrData", data);
            this.dialogQrScan = false;
            const hitData = this.qrcodeData[data];
            if (hitData) {
                this.goNext();
            }
        },
        goSkip() {
            this.viewYesNo = true;
            let movie = this.$refs.startMovie;
            movie.currentTime = movie.duration - 0.001;
            movie.pause();
        },
        goNext() {
            this.$store.commit("clearBilling");
            this.$router.replace({ name: 'intro' });
        },
        // checkVideoTime() {
        //     let movie = this.$refs.startMovie;
        //     console.log("TIME", movie.duration - movie.currentTime);
        //     if ((movie.duration - movie.currentTime) <= 0.001) {
        //         if (!this.viewYesNo) {
        //             this.viewYesNo = true;
        //         }
        //     } else {
        //         if (this.viewYesNo) {
        //             this.viewYesNo = false;
        //         }
        //     }

        //     this.videoTimer = setTimeout(() => {
        //         this.checkVideoTime();
        //     }, 200);
        // }
    },
    computed: {
    },
    watch: {
        dialogQrScan: function (now, old) {
            console.log(now)
            if (now == false) {
                this.$refs.btnYes.src = "/images/start/dounyu_yes.png"
            }
        },

        dialogNoStart: function (now, old) {
            if (now == false) {
                this.$refs.btnNo.src = "/images/start/dounyu_no.png"
            }
        },
    }
};
</script>

<template>
    <v-container class="baseStyle">
        <v-row>
            <v-col cols="12" class="ma-0 pa-0">
                <tegata v-if="nowStory == 11 || nowStory == 12 || nowStory == 18" :name="tegataName" :animation="true"
                    :stamp="nowStory == 18 ? 1 : 0" :ura="false" :receive="nowStory == 11"></tegata>

                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
                    height="100%" viewBox="0 0 427 830" v-else>
                    <g transform="translate(0 0)">
                        <image width="427" height="830" xlink:href="/images/rally1/bg.jpg"></image>
                    </g>
                    <g transform="translate(0 200)" v-if="nowStory == 2 || nowStory == 3">
                        <image width="427" height="168" xlink:href="/images/rally1/koma1.jpg" class="koma1"></image>
                    </g>
                    <g transform="translate(0 380)" v-if="nowStory == 3">
                        <image width="427" height="168" xlink:href="/images/rally1/koma2.jpg" class="koma2"></image>
                    </g>
                    <g transform="translate(0 236)" v-if="nowStory == 6">
                        <image width="427" height="338" xlink:href="/images/rally1/koma3.png" class="koma2"></image>
                    </g>
                    <g transform="translate(270 218)" v-if="nowStory == 6">
                        <image width="102" height="97" xlink:href="/images/rally1/rally1-10_manpu.png" class="fadeIn">
                        </image>
                    </g>
                    <g transform="translate(0 140)" v-if="nowStory == 14">
                        <image width="400" height="455" xlink:href="/images/rally1/rally1-18.png">
                        </image>
                    </g>
                </svg>
            </v-col>
        </v-row>
        <div class="msgArea">
            <div @click="storyNext">
                <MessageWindow :name="msgName" :text="msgText" :lock="isLockStory" :bgcolor="msgBgColor">
                </MessageWindow>
            </div>
        </div>

        <ConfWindow v-if="dialogConf" @close="eventConfOk" :text="'洞窟風呂で\nヴェルドラの尻尾に\n触れるとボイスが\nお楽しみいただけます。'">
        </ConfWindow>
        <NameInputWindow v-if="dialogNameInput" @close="eventNameInputOk"></NameInputWindow>

    </v-container>
</template>

<style scoped>
.baseStyle {
    position: relative;
    padding: 0 !important;
    width: 100%;
    max-width: 480px;
}

.msgArea {
    position: fixed;
    width: 100%;
    padding: 0 3vw;
    bottom: 30px;
    left: 0;
    text-align: center;
    z-index: 1;
}

.msgArea>div {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
}


.koma1 {
    transform: translateX(427px);
    animation-name: slideLeftRight;
    animation-duration: 0.6s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
}

.koma2 {
    transform: translateX(-427px);
    animation-name: slideRightLeft;
    animation-duration: 0.6s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
}

.fadeIn {
    opacity: 0;
    animation-name: fadeIn;
    animation-delay: 0.5s;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
}



@keyframes slideLeftRight {
    0% {
        transform: translateX(427px);
    }

    100% {
        transform: translateX(0px);
    }
}

@keyframes slideRightLeft {
    0% {
        transform: translateX(-427px);
    }

    100% {
        transform: translateX(0px);
    }
}

@keyframes fadeIn {

    0%,
    20% {
        opacity: 0;
    }

    20%,
    50% {
        opacity: 1;
    }

    50%,
    70% {
        opacity: 0;
    }

    70%,
    100% {
        opacity: 1;
    }
}
</style>
<script>
import ConfWindow from "./components/ConfWindow";
import MessageWindow from "./components/MessageWindow";
import NameInputWindow from "./components/NameInputWindow";
import Tegata from "./components/Tegata";

export default {
    components: {
        ConfWindow,
        MessageWindow,
        NameInputWindow,
        Tegata
    },
    data: () => ({
        dialogConf: false,
        dialogNameInput: false,
        nowStory: 0,
        msgName: '',
        msgText: '',
        msgBgColor: '#000000',
        lockStoryTimer: undefined,
        isLockStory: false,
        // inputName: "",
        tegataName: ""
    }),
    mounted: async function () {
        try {
            document.getElementsByTagName("html")[0].classList.add("noScroll")
        } catch (error) { }

        let appDom = document.getElementById("app");
        appDom.classList.add("rally1Bg");

        this.nowStory = this.$store.getters.viewStoryNum;
        if (this.nowStory > 19) {
            this.nowStory = 0;
        }

        if (this.nowStory == 0) {
            this.dialogConf = true;
        }
        // console.log("start", this.nowStory)
        this.storyEvent();
    },
    destroyed: function () {
        let appDom = document.getElementById("app");
        appDom.classList.remove("rally1Bg");
        appDom.classList.remove("tegataBg");

        try {
            document.getElementsByTagName("html")[0].classList.remove("noScroll")
        } catch (error) { }

    },
    methods: {
        storyNext(isForce) {
            if (isForce != true && this.isLockStory == true) {
                console.log("lock")
                return;
            }
            this.isLockStory = true;
            this.nowStory++;
            if (this.nowStory < 18) {
                this.$store.commit("setStoryNum", this.nowStory);
            }
            this.storyEvent();
        },
        storyEvent() {
            let nextTiming = 500;
            let appDom = document.getElementById("app");

            switch (this.nowStory) {
                case 0:
                    this.msgName = "リムル";
                    this.msgText = 'ようこそ、ここは\n温泉アミューズメントパーク';
                    break;
                case 1:
                    this.msgName = "リムル";
                    this.msgText = "ユネッサン・テンペストだ。";
                    break;
                case 2:
                    this.msgName = "リムル";
                    this.msgText = "俺はここの支配人、リムルだ。\nよろしく";
                    break;
                case 3:
                    this.msgName = "ヴェルドラ";
                    this.msgText = "クワッハッハッハ";
                    break;
                case 4:
                    this.msgName = "リムル";
                    this.msgText = "ユネッサン・テンペストといって\n俺の理想とする";
                    break;
                case 5:
                    this.msgName = "リムル";
                    this.msgText = "温泉アミューズメントパークを\n作ったんだ。";
                    break;
                case 6:
                    this.msgName = "リムル";
                    this.msgText = "そうだ、君の名前はなんて言うんだ？\n教えてくれ。";
                    break;
                case 7:
                    this.dialogNameInput = true;
                    break;
                case 8:
                    this.msgName = "リムル";
                    this.msgText = "そうか、" + this.$store.getters.inputName + "というのか。";
                    break;
                case 9:
                    this.msgName = "リムル";
                    this.msgText = "それではこの世界での名前を与える。";
                    break;
                case 10:
                    this.msgName = "リムル";
                    this.msgText = "" + this.$store.getters.userName + "だ。\nよろしくな！";
                    break;
                case 11:
                    this.msgBgColor = "#960000";
                    this.msgName = "";
                    this.msgText = "リムルから\n湯めぐり手形を受け取った！";
                    this.tegataName = "";
                    appDom.classList.remove("rally1Bg");
                    appDom.classList.add("tegataBg");
                    break;
                case 12:
                    this.msgName = "";
                    this.msgText = "湯めぐり手形に\nあなたの名前が刻まれた！";
                    this.tegataName = this.$store.getters.userName;
                    break;
                case 13:
                    this.msgBgColor = "#000000";
                    this.msgName = "ヴェルドラ";
                    this.msgText = "クワッハッハッハ、良き名ではないか。";
                    appDom.classList.add("rally1Bg");
                    appDom.classList.remove("tegataBg");
                    break;
                case 14:
                    this.msgName = "リムル";
                    this.msgText = "そうだ、ここにはまだ\nできたばかりで";
                    break;
                case 15:
                    this.msgName = "リムル";
                    this.msgText = "仲間たちみな\nバタバタしているんだ";
                    break;
                case 16:
                    this.msgName = "リムル";
                    this.msgText = "よければテンペスト内を\n見てきてほしいんだが";
                    break;
                case 17:
                    this.msgName = "リムル";
                    this.msgText = "お願いできるかな？";
                    break;
                case 18:
                    this.msgBgColor = "#960000";
                    this.msgName = "";
                    this.msgText = "湯めぐり手形に\n１つ目のスタンプが押された！";
                    appDom.classList.remove("rally1Bg");
                    appDom.classList.add("tegataBg");
                    this.$store.commit("clearRally1");
                    break;
                case 19:
                    this.goNext();
                // break;
                default:
                    this.msgBgColor = "#000000";
                    this.msgName = "";
                    this.msgText = "";
                    break;
            }
            this.lockStoryTimer = setTimeout(() => {
                this.isLockStory = false;
            }, nextTiming)
        },
        goNext() {
            this.$router.replace({ name: 'rallymap' });
        },
        eventConfOk() {
            this.dialogConf = false;
        },
        eventNameInputOk(name) {
            const fnames = ["テンペスト", "ジュラ", "ユネッサン"];
            let rand = Math.random();
            let rNum = Math.floor(rand * fnames.length);
            console.log("★NAME", rand, rNum, fnames[rNum]);
            let fname = fnames[rNum];
            // this.inputName = name;
            this.$store.commit("setUserName", { "input": name, "full": name + "・" + fname });
            this.dialogNameInput = false;
            this.storyNext();

        }
    },
    computed: {
    },
    watch: {

    }
};
</script>

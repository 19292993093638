<template>
    <v-container class="baseStyle">
        <v-row>
            <v-col cols="12" class="ma-0 pa-0">
                <tegata v-if="nowStory == 3" :name="$store.getters.userName" :animation="true" :stamp="2" :ura="false"
                    :receive="false">
                </tegata>

                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
                    height="100%" viewBox="0 0 427 1023" v-else>
                    <g transform="translate(0 0)">
                        <image width="427" height="1023" xlink:href="/images/rally2/bg.jpg"></image>
                    </g>
                    <g transform="translate(42 475)" v-if="nowStory == 0">
                        <image width="350" height="73" xlink:href="/images/rally2/ribbon.png" class="fadeIn"></image>
                    </g>
                    <g transform="translate(75 240)" v-if="nowStory == 2">
                        <image width="352" height="195" xlink:href="/images/rally2/koma1.jpg" class="koma1"></image>
                    </g>
                </svg>
            </v-col>
        </v-row>
        <div class="msgArea">
            <div @click="storyNext">
                <MessageWindow :name="msgName" :text="msgText" :lock="isLockStory" :bgcolor="msgBgColor">
                </MessageWindow>
            </div>
        </div>
    </v-container>
</template>

<style scoped>
.baseStyle {
    position: relative;
    padding: 0 !important;
    width: 100%;
    max-width: 480px;
}

.msgArea {
    position: fixed;
    width: 100%;
    padding: 0 3vw;
    bottom: 30px;
    left: 0;
    text-align: center;
    z-index: 1;
}

.msgArea>div {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
}


.koma1 {
    transform: translateX(427px);
    animation-name: slideLeftRight;
    animation-duration: 0.6s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
}

.fadeIn {
    opacity: 0;
    animation-name: fadeIn;
    animation-delay: 0.5s;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
}

@keyframes slideLeftRight {
    0% {
        transform: translateX(427px);
    }

    100% {
        transform: translateX(0px);
    }
}

@keyframes fadeIn {

    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
</style>
<script>
import MessageWindow from "./components/MessageWindow";
import Tegata from "./components/Tegata";

export default {
    components: {
        MessageWindow,
        Tegata
    },
    data: () => ({
        nowStory: 0,
        msgName: '',
        msgText: '',
        msgBgColor: '#000000',
        lockStoryTimer: undefined,
        isLockStory: false,
    }),
    mounted: async function () {
        try {
            document.getElementsByTagName("html")[0].classList.add("noScroll")
        } catch (error) { }

        let appDom = document.getElementById("app");
        appDom.classList.add("rally2Bg");
        this.nowStory = this.$store.getters.viewStoryNum;
        if (this.nowStory > 4) {
            this.nowStory = 0;
        }
        // console.log("PLAYSOTRY", this.nowStory);
        this.storyEvent();
    },
    destroyed: function () {
        try {
            document.getElementsByTagName("html")[0].classList.remove("noScroll")
        } catch (error) { }

        let appDom = document.getElementById("app");
        appDom.classList.remove("rally2Bg");
        appDom.classList.remove("tegataBg");
    },
    methods: {
        storyNext(isForce) {
            if (isForce != true && this.isLockStory == true) {
                console.log("lock")
                return;
            }
            this.isLockStory = true;
            this.nowStory++;
            if (this.nowStory < 3) {
                this.$store.commit("setStoryNum", this.nowStory);
            }
            this.storyEvent();
        },
        storyEvent() {
            let nextTiming = 500;
            // console.log(this.nowStory)
            clearTimeout(this.autoStoryTimer);

            let appDom = document.getElementById("app");

            switch (this.nowStory) {
                case 0:
                    this.msgName = "ソウエイ";
                    this.msgText = '私の分身体と会っていたのは\nあなただったんですね。';
                    break;
                case 1:
                    this.msgName = "ソウエイ";
                    this.msgText = "手にもたれているのは、\nリムル様より渡された湯巡り手形ですか。";
                    break;
                case 2:
                    this.msgName = "ソウエイ";
                    this.msgText = "こちらの印を押させてもらいます。";
                    break;
                case 3:
                    this.msgBgColor = "#960000";
                    this.msgName = "";
                    this.msgText = "湯めぐり手形に\n２つ目のスタンプが押された！";
                    appDom.classList.remove("rally2Bg");
                    appDom.classList.add("tegataBg");

                    this.$store.commit("clearRally2");
                    break;
                case 4:
                    this.goNext();
                // break;
                default:
                    this.msgBgColor = "#000000";
                    this.msgName = "";
                    this.msgText = "";
                    break;
            }
            this.lockStoryTimer = setTimeout(() => {
                this.isLockStory = false;
            }, nextTiming)
        },
        goNext() {
            this.$router.replace({ name: 'rallymap' });
        },
        eventConfOk() {
            this.dialogConf = false;
        },
        eventNameInputOk(name) {
            const fnames = ["テンペスト", "ジュラ", "ユネッサン"];
            let fname = fnames[Math.floor(Math.random() * fnames.length)];
            this.inputName = name;
            this.$store.commit("setUserName", name + "・" + fname);
            this.dialogNameInput = false;
            this.storyNext();
        }
    },
    computed: {
    },
    watch: {

    }
};
</script>

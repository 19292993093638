<template>
    <v-container>
        <div class="msgArea">
            <div @click="goNext">
                <MessageWindow name="" :text="'これは分身体だった！\n他の場所をまわろう！'" :lock="false" :bgcolor="'#000000'">
                </MessageWindow>
            </div>
        </div>
    </v-container>
</template>

<style scoped>
.msgArea {
    position: fixed;
    width: 100%;
    padding: 0 3vw;
    bottom: 30px;
    left: 0;
    text-align: center;
    z-index: 1;
}
</style>
<script>
import MessageWindow from "./components/MessageWindow";

export default {
    components: {
        MessageWindow,
    },
    data: () => ({
    }),
    created: function () {
    },
    mounted: function () {
        try {
            document.getElementsByTagName("html")[0].classList.add("noScroll")
        } catch (error) { }

        let appDom = document.getElementById("app");
        appDom.classList.add("rally2NextBg");
    },
    destroyed: function () {
        try {
            document.getElementsByTagName("html")[0].classList.remove("noScroll")
        } catch (error) { }

        let appDom = document.getElementById("app");
        appDom.classList.remove("rally2NextBg");
    },
    methods: {
        goNext() {
            this.$router.replace({ name: 'rallymap' });
        },
    },
    computed: {
    },
    watch: {

    }
};
</script>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userName: undefined,
        inputName: undefined,
        rally: [[false, false], [false, false], [false, false, false, false], [false, false], [false, false, false, false], [false, false, false]],
        checkTos: false,
        checkDevice: false,
        localUserHashKey: null,
        lineUserHashKey: null,
        hashPrefix: "ytensra",
        isMaintenance: false,
        isClearTiming: false,
        isUpdateTegata: false,
        viewStoryNum: 0,
        code: [
            { "https://line.me/R/ti/p/oxFaFzoy-9?key=japx0a": { "id": "0a" } },
            { "https://line.me/R/ti/p/oxFaFzoy-9?key=nbsu1a": { "id": "1a" } },
            { "https://line.me/R/ti/p/oxFaFzoy-9?key=scek2a": { "id": "2a", "num": 0 }, "https://line.me/R/ti/p/oxFaFzoy-9?key=qchu2b": { "id": "2b", "num": 1 }, "https://line.me/R/ti/p/oxFaFzoy-9?key=zcrb2c": { "id": "2c", "num": 2 } },
            { "https://line.me/R/ti/p/oxFaFzoy-9?key=pdwy3a": { "id": "3a" } },
            { "https://line.me/R/ti/p/oxFaFzoy-9?key=xesb4b": { "id": "4a", "num": 1 }, "https://line.me/R/ti/p/oxFaFzoy-9?key=dehx4c": { "id": "4b", "num": 2 } },
            { "https://line.me/R/ti/p/oxFaFzoy-9?key=afuq5a": { "id": "5a" } }
        ],
        nowPlayRally: {}
    },
    getters: {
        userName: state => state.userName,
        inputName: state => state.inputName,
        rally: state => state.rally,
        rally0: state => state.rally[0],
        rally1: state => state.rally[1],
        rally2: state => state.rally[2],
        rally3: state => state.rally[3],
        rally4: state => state.rally[4],
        rally5: state => state.rally[5],
        isClearRally0: (state) => {
            let mi = state.rally[0];
            return (mi && mi[0] == true && mi[1] == true);
        },
        isClearBilling: (state) => {
            let mi = state.rally[0];
            return (mi && mi[0] == true && mi[1] == false);
        },
        isClearRally1: (state) => {
            let mi = state.rally[1];
            return (mi && mi[0] == true && mi[1] == true);
        },
        nowPlayRally: (state) => {
            return state.nowPlayRally;
        },
        code: state => state.code,
        hashPrefix: state => state.hashPrefix,
        isMaintenance: state => state.isMaintenance,
        isCheckTos: state => state.checkTos,
        isCheckDevice: state => state.checkDevice,
        localUserHashKey: state => state.localUserHashKey,
        lineUserHashKey: state => state.lineUserHashKey,
        isClearTiming: state => state.isClearTiming,
        isUpdateTegata: state => state.isUpdateTegata,
        viewStoryNum: state => state.viewStoryNum,
    },
    mutations: {
        init(state, data) {
            console.log("INIT", data);
            if (!data) return;
            state.checkTos = data.checkTos;
            state.localUserHashKey = data.localUserHashKey;
            state.lineUserHashKey = data.lineUserHashKey;
            if (data.rally) {
                state.rally = data.rally;
            }
            state.userName = data.userName;
        },
        resetData(state, data) {
            state.rally = [[false, false], [false, false], [false, false, false, false], [false, false], [false, false, false, false], [false, false, false]];
        },
        clearBilling(state, data) {
            state.rally[0] = [true, false];
            state.viewStoryNum = 0;
        },
        clearRally0(state, data) {
            state.rally[0] = [true, true];
            state.viewStoryNum = 0;
        },

        checkRally1Qr(state, data) {
            state.rally[1] = [true, false];
            state.viewStoryNum = 0;
        },
        clearRally1(state, data) {
            state.rally[1] = [true, true];
            state.viewStoryNum = 0;
        },

        checkRally2a(state, data) {
            state.rally[2][0] = true;
            state.viewStoryNum = 0;
        },
        checkRally2b(state, data) {
            state.rally[2][1] = true;
            state.viewStoryNum = 0;
        },
        checkRally2c(state, data) {
            state.rally[2][2] = true;
            state.viewStoryNum = 0;
        },
        clearRally2(state, data) {
            state.rally[2] = [true, true, true, true];
            state.viewStoryNum = 0;
        },

        checkRally3Qr(state, data) {
            state.rally[3] = [true, false];
            state.viewStoryNum = 0;
        },
        clearRally3(state, data) {
            state.rally[3] = [true, true];
            state.viewStoryNum = 0;
        },

        checkRally4Intro(state, data) {
            state.rally[4] = [true, false, false, false];
            state.viewStoryNum = 0;
        },
        checkRally4a(state, data) {
            state.rally[4][1] = true;
            state.viewStoryNum = 0;
        },
        checkRally4b(state, data) {
            state.rally[4][2] = true;
            state.viewStoryNum = 0;
        },
        clearRally4(state, data) {
            state.rally[4] = [true, true, true, true];
            state.viewStoryNum = 0;
        },

        checkRally5Qr(state, data) {
            state.rally[5] = [true, false, false];
            state.viewStoryNum = 0;
        },
        checkRally5Tegata(state, data) {
            state.rally[5] = [true, true, false];
            state.isUpdateTegata = true;
            state.viewStoryNum = 0;
        },
        clearRally5(state, data) {
            state.rally[5] = [true, true, true];
            state.isClearTiming = true;
            state.viewStoryNum = 0;
        },

        setMaintenance(state, data) {
            state.isMaintenance = data;
        },
        setCheckDevice(state, data) {
            state.checkDevice = data;
        },
        setCheckTos(state, data) {
            state.checkTos = data;
        },
        // setLocalUserHashKey(state, data) {
        //     state.localUserHashKey = data;
        // },
        setLineLocalUserHashKey(state, data) {
            state.localUserHashKey = data.localUserHashKey;
            state.lineUserHashKey = data.lineUserHashKey;
        },
        setUserName(state, data) {
            state.inputName = data.input;
            state.userName = data.full;
        },
        setNowPlayRally(state, data) {
            state.nowPlayRally = data;
        },
        setUpdateTegata(state, data) {
            state.isUpdateTegata = data;
        },
        setClearTiming(state, data) {
            state.isClearTiming = data;
        },
        setStoryNum(state, data) {
            state.viewStoryNum = data;
        }
    },
    actions: {
        initParam(ctx) {
            // const params = new URLSearchParams(location.search);
            // ctx.state.shopKey = params.get("key");
            // ctx.state.urlParamId = params.get("id");
        },
        clearRally3(ctx, payload) {
            console.log("SAVE", payload)
            ctx.state.rally[3] = [true, true];
            return "a";
        }
    }
})
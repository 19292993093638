<template>
    <div>
        <v-dialog v-model="isOpen" :max-width="dialogMaxWidth" persistent>
            <v-card>
                <v-card-title class="titleFont">遊び方</v-card-title>
                <v-card-text class="text-center">
                    <Flicking :options="{
                        panel: 1,
                    }" :plugins="plugins" @ready="flickInit" ref="flick">
                        <div class="dialogFlickdiv">
                            <v-row>
                                <v-col cols="12" class="text-left textMain">
                                    湯めぐりをしながら、開業の準備で慌ただしくしているリムルたちに会いに行きましょう。
                                </v-col>
                                <v-col cols="12">
                                    <img src="/images/howtoplay/slide1.jpg" width="60%" />
                                </v-col>
                            </v-row>
                        </div>
                        <div class="dialogFlickdiv"><v-row>
                                <v-col cols="12" class="text-left textMain">
                                    マップに示された場所に向かい、QRコードを読み取るとストーリーが進行します。
                                </v-col>
                                <v-col cols="12">
                                    <img src="/images/howtoplay/slide2.jpg" width="60%" />
                                </v-col>
                            </v-row></div>
                        <div class="dialogFlickdiv"><v-row>
                                <v-col cols="12" class="text-left textMain">
                                    進行を保存するために、必ずLINE内ブラウザを使用して遊んでください。
                                </v-col>
                                <v-col cols="12">
                                    <img src="/images/howtoplay/slide3.jpg" width="80%" />
                                </v-col>
                            </v-row></div>
                        <div class="dialogFlickdiv"><v-row>
                                <v-col cols="12" class="text-left textMain">
                                    QRコードの読み取りやカメラ撮影機能を利用するので、カメラの利用は許諾をお願いします。
                                </v-col>
                                <v-col cols="12">
                                    <img src="/images/howtoplay/slide4.jpg" width="70%" />
                                </v-col>
                                <v-col cols="12">
                                    <v-btn @click="windowClose" class="btn_orange">TOPへ</v-btn>
                                </v-col>
                            </v-row></div>
                        <div slot="viewport" class="flicking-pagination mt-5"></div>
                    </Flicking>

                </v-card-text>
            </v-card>

        </v-dialog>
    </div>


</template>
<style scoped src='@/assets/style/windowAkawaku.css'>

</style>

<style scoped>
.titleFont {
    font-family: 'Noto Sans JP', sans-serif !important;
    font-weight: bold !important;
}

.textMain {
    font-family: 'Noto Sans JP', sans-serif !important;
    font-size: 16px;
    line-height: 22px;
}

.dialogFlickdiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    font-size: min(5vw, 18px);
}
</style>
<style>
.flicking-pagination {
    margin-top: 10px
}

.flicking-pagination-bullet::after {
    width: 20px;
    margin: 2vw;
    vertical-align: top;
    content: url("@/assets/htpBtnOther.svg") !important;
}

.flicking-pagination-bullet-active::after {
    content: url("@/assets/htpBtnNow.svg") !important;
}
</style>
<script>
import { Flicking } from "@egjs/vue-flicking";
import { Pagination } from "@egjs/flicking-plugins";


export default {
    data: () => ({
        isOpen: true,
        dialogMaxWidth: 400,
        plugins: [new Pagination({ type: 'bullet' })]

    }),
    components: {
        Flicking,
    },
    props: {},
    created: function () {
        if (this.dialogMaxWidth * 1.5 > window.innerHeight) {
            this.dialogMaxWidth = window.innerHeight / 1.6;
            this.dialogCloseBtnStyle = "width:min(8vw, 48px) !important";
        }
    },
    destroyed: function () {
    },
    methods: {
        windowClose() {
            this.isOpen = false;
        },
        flickInit(e) {
            e.currentTarget.moveTo(0);
        }
    },
    computed: {
    },
    watch: {
        isOpen(newVal) {
            if (!newVal) {
                this.$emit('close', this.data);
            }
        }
    }
}
</script>
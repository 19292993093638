<template>
    <v-container class="baseStyle">
        <v-row>
            <v-col cols="12" class="ma-0 pa-0">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
                    height="100%" viewBox="0 0 427 921">
                    <g transform="translate(0 0)">
                        <image width="427" height="921" xlink:href="/images/rally4/bg.jpg"></image>
                    </g>
                    <g transform="translate(205 204)">
                        <image width="222" height="616" xlink:href="/images/rally4/benimaru.png"></image>
                    </g>
                    <g transform="translate(0 225)" v-if="nowStory >= 1 && nowStory <= 2">
                        <image width="427" height="313" xlink:href="/images/rally4/koma1.png" class="koma1"></image>
                    </g>
                    <g transform="translate(28 200)" v-if="nowStory >= 3">
                        <image width="190" height="662" xlink:href="/images/rally4/diabro.png"></image>
                    </g>
                    <g transform="translate(0 218)" v-if="nowStory == 4">
                        <image width="427" height="315" xlink:href="/images/rally4/koma2.png" class="koma2"></image>
                    </g>
                    <g transform="translate(76 258)" v-if="nowStory >= 5">
                        <image width="276" height="275" xlink:href="/images/rally4/phone.png" class="fadeIn"></image>

                    </g>
                    <!-- <image width="234" height="647" xlink:href="/images/rally4/benimaru.png"></image> -->
                    <!-- <image width="345" height="1199" xlink:href="/images/rally4/diabro.png"></image> -->

                </svg>
            </v-col>
        </v-row>
        <div class="msgArea">
            <div @click="storyNext">
                <MessageWindow :name="msgName" :text="msgText" :lock="isLockStory" :bgcolor="msgBgColor">
                </MessageWindow>
            </div>
        </div>

    </v-container>
</template>

<style scoped>
.baseStyle {
    position: relative;
    padding: 0 !important;
    width: 100%;
    max-width: 480px;
}

.msgArea {
    position: fixed;
    width: 100%;
    padding: 0 3vw;
    bottom: 30px;
    left: 0;
    text-align: center;
    z-index: 1;
}

.msgArea>div {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
}


.koma2 {
    transform: translateX(427px);
    animation-name: slideLeftRight;
    animation-duration: 0.6s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
}

.koma1 {
    transform: translateX(-427px);
    animation-name: slideRightLeft;
    animation-duration: 0.6s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
}

.fadeIn {
    opacity: 0;
    animation-name: fadeIn;
    animation-delay: 0.5s;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
}



@keyframes slideLeftRight {
    0% {
        transform: translateX(427px);
    }

    100% {
        transform: translateX(0px);
    }
}

@keyframes slideRightLeft {
    0% {
        transform: translateX(-427px);
    }

    100% {
        transform: translateX(0px);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
</style>
<script>
import MessageWindow from "./components/MessageWindow";

export default {
    components: {
        MessageWindow,
    },
    data: () => ({
        nowStory: 0,
        msgName: '',
        msgText: '',
        msgBgColor: '#000000',
        lockStoryTimer: undefined,
        isLockStory: false,
    }),
    mounted: async function () {
        try {
            document.getElementsByTagName("html")[0].classList.add("noScroll")
        } catch (error) { }

        let appDom = document.getElementById("app");
        appDom.classList.add("rally4Bg");
        this.nowStory = this.$store.getters.viewStoryNum;
        if (this.nowStory > 6) {
            this.nowStory = 0;
        }
        this.storyEvent();
    },
    destroyed: function () {
        try {
            document.getElementsByTagName("html")[0].classList.remove("noScroll")
        } catch (error) { }

        let appDom = document.getElementById("app");
        appDom.classList.remove("rally4Bg");
    },
    methods: {
        storyNext(isForce) {
            if (isForce != true && this.isLockStory == true) {
                console.log("lock")
                return;
            }
            this.isLockStory = true;
            this.nowStory++;
            if (this.nowStory < 7) {
                this.$store.commit("setStoryNum", this.nowStory);
            }
            this.storyEvent();
        },
        storyEvent() {
            let nextTiming = 500;
            // console.log(this.nowStory)
            clearTimeout(this.autoStoryTimer);

            // let appDom = document.getElementById("app");

            switch (this.nowStory) {
                case 0:
                    this.msgName = "ベニマル";
                    this.msgText = 'あなたは、リムル様のご客人ですね。';
                    break;
                case 1:
                    this.msgName = "ベニマル";
                    this.msgText = "ここには多くの\n温泉と呼ばれる場所があるんですが、";
                    break;
                case 2:
                    this.msgName = "ベニマル";
                    this.msgText = "よければ見てきてもらえないだろうか。";
                    break;
                case 3:
                    this.msgName = "ディアブロ";
                    this.msgText = "リムル様より授かった\n大事な命(めい)ですので";
                    break;
                case 4:
                    this.msgName = "ディアブロ";
                    this.msgText = "わたくしが\n同行させていただきましょう。";
                    break;
                case 5:
                    this.msgName = "ベニマル";
                    this.msgText = "各地の温泉には\n印(しるし)があるから";
                    break;
                case 6:
                    this.msgName = "ベニマル";
                    this.msgText = "それをとってきて欲しい。";
                    break;
                case 7:
                    this.goNext();
                default:
                    this.msgBgColor = "#000000";
                    this.msgName = "";
                    this.msgText = "";
                    break;
            }
            this.lockStoryTimer = setTimeout(() => {
                this.isLockStory = false;
            }, nextTiming)
        },
        goNext() {
            this.$store.commit("checkRally4Intro");
            this.$router.replace({ name: 'rallymap' });
        }
    },
    computed: {
    },
    watch: {

    }
};
</script>

<template>
    <v-dialog v-model="isOpen" :max-width="dialogMaxWidth" persistent>
        <v-card>
            <v-card-title>あなたの名前は？</v-card-title>
            <v-card-text>
                <div class="text-center" style="width:100%">
                    <input type="text" v-model="inputName" placeholder="全角カタカナ５文字以内" class="inputStyle" />
                </div>
                <div class="errorStyle text-center" style="width:100%">{{ errorMsg }}</div>
                <ul>
                    <li>※変更できません</li>
                    <li>※全角カタカナ５文字以内</li>
                </ul>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="eventInputOk" class="btn_orange" :disabled="inputName.length == 0">決定！</v-btn>
            </v-card-actions>
        </v-card>

    </v-dialog>
</template>
<style scoped src='@/assets/style/windowAkawaku.css'>

</style>
<style scoped>
ul {
    margin: 1em;
    margin-top: 2em;
    list-style-type: none;
    padding: 0;
}

li {
    padding-top: 0.1em;
    padding-bottom: 0.1em;
    font-size: 0.9em;
}

.inputStyle {
    width: 90%;
    background-color: white;
    border: 1px solid gray;
    padding: 10px;
    text-align: center;
    margin: 0 auto;
}

.errorStyle {
    color: white;
    font-size: 0.7em;
    height: 0.7em;
}
</style>
<script>

export default {
    data: () => ({
        isOpen: true,
        dialogMaxWidth: 400,
        inputName: "",
        errorMsg: "",
        errorMsgTimer: undefined
    }),
    props: {},
    created: function () {
        if (this.dialogMaxWidth * 1.5 > window.innerHeight) {
            this.dialogMaxWidth = window.innerHeight / 1.6;
            this.dialogCloseBtnStyle = "width:min(8vw, 48px) !important";
        }
    },
    destroyed: function () {
    },
    methods: {
        eventInputOk() {
            this.windowClose();
        },
        windowClose() {
            this.isOpen = false;
        }
    },
    computed: {
    },
    watch: {
        isOpen(newVal) {
            if (!newVal) {
                this.$emit('close', this.inputName);
            }
        },
        inputName(newVal) {
            let msg = "";
            let delText = newVal.replace(/[^ァ-ヴー]/gi, '');
            if (newVal != delText) {
                msg = "全角カタカナ以外は入力できません";
            }
            if (delText.length > 5) {
                delText = delText.substring(0, 5);
                msg = "５文字以上は入力できません"
            }
            if (this.inputName != delText) {
                clearTimeout(this.errorMsgTimer);
                this.errorMsg = msg
                this.inputName = delText;
                this.errorMsgTimer = setTimeout(() => {
                    this.errorMsg = "";
                }, 3000);
            }
        }
    }
}
</script>
export default class Stamp {
    constructor() {
        this.canvasW = 320;
        this.canvasH = 464;
        this.camera = document.createElement("video");
        this.camera.setAttribute("autoplay", "");
        this.camera.setAttribute("muted", "");
        this.camera.setAttribute("playsinline", "");
        this.capCanvas = null;
        this.capContext = null;
        this.eventFunc = null;
        this.mode = null;
        this.frameImg = null;
        this.isFrontCamera = false;
        this.targetImg = null;
        this.targetImages = null;
    }

    async checkUseDevice() {
        try {
            let stream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: false
            });
            if (stream) {
                stream.getVideoTracks().forEach((track) => {
                    track.stop();
                });
                stream.getAudioTracks().forEach((track) => {
                    track.stop();
                });
            }
            return true;
        } catch (e) {
            console.log(e);
            // switch (e.name) {
            //     case "NotAllowedError":
            throw e;
            // }
        }
        return false;
    }

    async qrCamera(target, evfunc, startFunc) {
        this.stopCamera();
        this.mode = "qr";

        try {
            if (target.tagName.toLowerCase() == "canvas") {
                this.capCanvas = target;
                this.targetImg = null;
            } else if (target.tagName.toLowerCase() == "img") {
                this.targetImg = target;
                this.capCanvas = document.createElement("canvas");
                this.capCanvas.width = 320;
                this.capCanvas.height = 464;
            } else {
                throw new Error("target tag type error")
            }
        } catch (error) {
            throw error;
        }


        this.capContext = this.capCanvas.getContext('2d');
        this.eventFunc = evfunc;
        this.canvasW = this.capCanvas.width;
        this.canvasH = this.capCanvas.height;
        try {
            await this.createCamera(false, startFunc);
        } catch (error) {
            throw error;
        }
    }

    async shotCamera(target, framePath, startFunc, isChangeFrame, targetList) {
        if (!target) {
            throw new Error("target not find error")
        }

        this.targetImages = targetList;

        if (!isChangeFrame) {
            this.stopCamera();
        }
        this.mode = "shot";

        const loadImage = function (src) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve(img);
                img.onerror = (e) => reject(e);
                img.src = src;
            });
        }

        try {
            this.frameImg = await loadImage(framePath);
            if (!this.frameImg) throw new Error("image error");
        } catch (error) {
            console.log(error);
            this.frameImg = null;
            throw new Error("not load frame");
        }


        if (target.tagName.toLowerCase() == "canvas") {
            this.capCanvas = target;
            this.targetImg = null;
            this.capCanvas.height = this.capCanvas.width * (this.frameImg.height / this.frameImg.width);
        } else if (target.tagName.toLowerCase() == "img") {
            this.targetImg = target;
            this.capCanvas = document.createElement("canvas");
            this.capCanvas.width = this.frameImg.width / 2;
            this.capCanvas.height = this.frameImg.height / 2;
        } else {
            throw new Error("target tag type error")
        }

        this.capContext = this.capCanvas.getContext('2d');
        this.canvasW = this.capCanvas.width;
        this.canvasH = this.capCanvas.height;

        if (!isChangeFrame) {
            await this.createCamera(true, startFunc, isChangeFrame);
        } else {
            startFunc.apply(null, [true]);
        }
    }

    async createCamera(useFront, startFunc) {

        if (!this.camera) {
            return;
        }

        let stream = this.camera.srcObject;
        if (stream) {
            stream.getVideoTracks().forEach((track) => {
                track.stop();
            });
            stream.getAudioTracks().forEach((track) => {
                track.stop();
            });
        }

        stream = undefined;

        let facingMode = {
            width: { ideal: 1280 },
            facingMode: "user"
        };
        if (!useFront) {
            facingMode = {
                width: { ideal: 1280 },
                facingMode: { exact: "environment" }
            };
            this.isFrontCamera = false;
        } else {
            this.isFrontCamera = true;
        }

        try {
            stream = await navigator.mediaDevices.getUserMedia({ video: facingMode });
        } catch (e) {
            // console.log(e);
        }
        if (stream == undefined) {
            try {
                stream = await navigator.mediaDevices.getUserMedia({ video: { width: { ideal: 1280 } } });
                this.isFrontCamera = true;
            } catch (error) {
                throw error;
            }
        }
        // console.log("BBB", stream);

        try {
            this.camera.srcObject = stream;
            this.camera.onloadedmetadata = (e) => {
                this.camera.play();
                this.cameraUpdate();
                if (startFunc) {
                    startFunc.apply(null, [true]);
                }
            };

        } catch (e) {
            console.log(e);
        }

    }

    switchCamera(startFunc) {
        this.isFrontCamera = !this.isFrontCamera;
        this.createCamera(this.isFrontCamera, startFunc);
    }

    getShotCameraData() {
        const bigCanvas = document.createElement("canvas");
        const bicContext = bigCanvas.getContext('2d');
        let sx, sy, sw, sh;

        try {

            let vw = this.camera.videoWidth;
            let vh = this.camera.videoHeight;

            if (!vw || !vh) {
                throw new Error();
            }

            if (vw < vh) {
                // 縦長
                sw = vw;
                sh = vw;
                sy = (vh - vw) / 2;
                sx = 0;
            } else {
                // 横長
                sw = vh;
                sh = vh;
                sy = 0;
                sx = (vw - vh) / 2;
            }

            const imgW = this.frameImg.width;
            const imgH = this.frameImg.height;

            bigCanvas.width = imgW;
            bigCanvas.height = imgH;

            // console.log("videoWidth", this.camera.videoWidth, "videoHeight", this.camera.videoHeight)
            // console.log("draw", "sx", sx, "sy", sy, "sw", sw, "sh", sh);
            // console.log("img", "imgW", imgW, "imgH", imgH);

            if (this.mode == "shot" && this.isFrontCamera) {
                // フロントは反転する
                bicContext.save();
                bicContext.translate(imgW, 0);
                bicContext.scale(-1, 1);
                bicContext.drawImage(this.camera, sx, sy, sw, sh, 0, 0, imgW, imgW);
                // bicContext.drawImage(this.camera, sx, sy, sw, sh, 0, imgH - imgW, imgW, imgW);
                bicContext.restore()
            } else {
                bicContext.drawImage(this.camera, sx, sy, sw, sh, 0, 0, imgW, imgW);
                // bicContext.drawImage(this.camera, sx, sy, sw, sh, 0, imgH - imgW, imgW, imgW);
            }
            bicContext.drawImage(this.frameImg, 0, 0, imgW, imgH, 0, 0, bigCanvas.width, bigCanvas.height);

            return bigCanvas.toDataURL("image/jpeg")

        } catch (error) {
            console.log(error);
        }

        return null;
    }


    stopCamera() {
        if (!this.camera) {
            return;
        }
        let stream = this.camera.srcObject;
        if (stream) {
            stream.getVideoTracks().forEach((track) => {
                track.stop();
            });
            stream.getAudioTracks().forEach((track) => {
                track.stop();
            });
        }
        this.camera.srcObject = null;
        this.eventFunc = null;
        this.mode = null;
        this.frameImg = null;
    }


    async cameraUpdate() {
        if (this.camera.srcObject == null) {
            console.log("srcObject END");
            return;
        }
        let sx, sy, sw, sh;

        try {

            let vw = this.camera.videoWidth;
            let vh = this.camera.videoHeight;

            if (!vw || !vh) {
                throw new Error();
            }

            if (vw < vh) {
                // 縦長
                sw = vw;
                sh = vw;
                sy = (vh - vw) / 2;
                sx = 0;
            } else {
                // 横長
                sw = vh;
                sh = vh;
                sy = 0;
                sx = (vw - vh) / 2;
            }


            if (this.mode == "shot" && this.isFrontCamera) {
                // フロントは反転する
                this.capContext.save();
                this.capContext.translate(this.canvasW, 0);
                this.capContext.scale(-1, 1);
                this.capContext.drawImage(this.camera, sx, sy, sw, sh, 0, 0, this.canvasW, this.canvasW);
                // this.capContext.drawImage(this.camera, sx, sy, sw, sh, 0, this.canvasH - this.canvasW, this.canvasW, this.canvasW);
                this.capContext.restore();
            } else {
                this.capContext.drawImage(this.camera, sx, sy, sw, sh, 0, 0, this.canvasW, this.canvasW);
                // this.capContext.drawImage(this.camera, sx, sy, sw, sh, 0, this.canvasH - this.canvasW, this.canvasW, this.canvasW);
            }


            if (this.mode == "qr") {
                let imageData = this.capContext.getImageData(0, 0, this.canvasW, this.canvasH);
                let code = jsQR(imageData.data, imageData.width, imageData.height, {
                    inversionAttempts: "dontInvert",
                });

                if (code) {
                    if (this.eventFunc) {
                        this.eventFunc.apply(null, [code]);
                    }
                }
            } else if (this.mode == "shot" && this.targetImages) {
                for (let i in this.targetImages) {
                    if (this.targetImages[i].target) {
                        let pCanvas = document.createElement("canvas");
                        pCanvas.width = this.capCanvas.width;
                        pCanvas.height = this.capCanvas.height;

                        let pContext = pCanvas.getContext('2d');
                        pContext.drawImage(this.capCanvas, 0, 0);
                        pContext.drawImage(this.targetImages[i].frameImg, 0, 0, this.targetImages[i].frameImg.width, this.targetImages[i].frameImg.height, 0, 0, this.canvasW, this.canvasH);
                        this.targetImages[i].target.src = pCanvas.toDataURL("image/jpeg")
                    }
                }
            } else if (this.mode == "shot" && this.frameImg) {
                this.capContext.drawImage(this.frameImg, 0, 0, this.frameImg.width, this.frameImg.height, 0, 0, this.canvasW, this.canvasH);
            }

            if (!this.targetImages && this.targetImg) {
                this.targetImg.src = this.capCanvas.toDataURL("image/jpeg");
            }

            requestAnimationFrame(() => {
                this.cameraUpdate();
            });
        } catch (e) {
            console.log(e);
        }



    }

}
<template>
    <v-container class="baseStyle">
        <div class="charArea text-center">
            <div class="charAtt ma-0 pa-0" v-if="nowStory < 10">
                <img src="/images/intro/c_1.png" :class="nowStory == 1 ? 'charFadeIn' : ''" width="70%"
                    v-if="nowStory >= 1 && nowStory <= 3" class="charPos">
                <img src="/images/intro/logo.jpg" width="100%" :class="nowStory == 4 ? 'charSlideIn' : ''"
                    v-if="nowStory >= 4 && nowStory <= 6">
                <img width="70%" src="/images/intro/c_2.png" v-if="nowStory >= 7 && nowStory <= 9" class="charPos">
                <img src="/images/intro/c_att_1.png" class="charAtt1 charPos" v-if="nowStory == 7">
                <img src="/images/intro/c_att_2.png" class="charAtt2 charPos" v-if="nowStory == 8">
            </div>
            <v-row v-if="nowStory == 10">
                <v-col cols="12" class="text-center">
                    「ユネッサン・テンペスト」の<br />
                    最初のお客さんとして<br />
                    やってきたあなた。<br />
                    <br />
                    心躍りながらやってきたが、<br />
                    個性豊かなスタッフは忙しげ。<br />
                    <br />
                    開業の準備でバタバタ<br />
                    だったのだろうか。
                </v-col>
                <v-col cols="12" class="text-center mt-15">
                    <v-btn @click="goHowToPlay" class="btn_orange">進む</v-btn>
                </v-col>
            </v-row>

        </div>


        <div class="msgArea" v-if="nowStory < 10">
            <div @click="storyNext">
                <MessageWindow :name="msgName" :text="msgText" :lock="isLockStory" :bgcolor="'#000000'"></MessageWindow>
            </div>
        </div>
        <HowtoPlayWindow v-if="dialogHtp" @close="eventHtp"></HowtoPlayWindow>
    </v-container>
</template>

<style scoped>
.baseStyle {
    position: relative;
    padding: 0 !important;
    width: 100%;
    max-width: 480px;
}


.msgArea {
    position: fixed;
    width: 100%;
    padding: 0 3vw;
    bottom: 30px;
    left: 0;
    text-align: center;
    z-index: 1;
}

.msgArea>div {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
}

.charArea {
    /* position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-40%);
    margin: auto;
    width: 100%;
    max-width: 480px;
    aspect-ratio: 480/396; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0;
    margin: 0;
    max-width: 480px;
    padding-bottom: 17vh;
}

.charPos {
    padding-top: 10vh;
}

.charAtt {
    position: relative;
    max-width: 480px;
    aspect-ratio: 480/396;
    /* max-width: 480px; */
}

.charAtt1 {
    position: absolute;
    /* top: max(-10vw, -70px);
    left: min(10vw, 10px); */
    top: max(-10vw, -70px);
    left: min(18vw, 18px);
    width: 30vw;
    max-width: 144px;
    animation: charAttAnime 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    transform-origin: center center;
}

.charAtt2 {
    position: absolute;
    top: max(-15vw, -80px);
    left: min(62vw, 326px);
    width: 30vw;
    max-width: 144px;
    animation: charAttAnime 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    transform-origin: center center;
}

.charSlideIn {
    transform: translateX(-427px);
    animation: charSlideInAnime 2s;
    animation-fill-mode: forwards;
}

.charFadeIn {
    opacity: 0;
    animation: charFadeInAnime 1s;
    animation-fill-mode: forwards;
}

.charFadeOut {
    opacity: 1;
    animation: charFadeOutAnime 1s;
    animation-fill-mode: forwards;
}

@keyframes charSlideInAnime {
    0% {
        transform: translateX(-427px);
    }

    100% {
        transform: translateX(0px);
    }
}

@keyframes charFadeInAnime {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes charFadeOutAnime {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes charAttAnime {
    0% {
        transform: scale(1) rotate(0) translate(0, 0)
    }

    7% {
        transform: scale(0.8) rotate(-5deg) translate(0, 0)
    }

    14% {
        transform: scale(1.2) rotate(5deg) translate(0, 0)
    }

    21% {
        transform: scale(1) rotate(0) translate(0, 0)
    }

    100% {
        transform: scale(1) rotate(0) translate(0, 0)
    }
}
</style>
<script>
import HowtoPlayWindow from "./components/HowtoPlayWindow";
import MessageWindow from "./components/MessageWindow";

export default {
    components: {
        HowtoPlayWindow,
        MessageWindow,
    },
    data: () => ({
        dialogHtp: false,
        nowStory: 0,
        autoStoryTimer: undefined,
        lockStoryTimer: undefined,
        isLockStory: false,
        msgName: "",
        msgText: "",
    }),
    created: function () {
    },
    mounted: function () {
        try {
            document.getElementsByTagName("html")[0].classList.add("noScroll")
        } catch (error) { }

        let appDom = document.getElementById("app");
        appDom.classList.add("introBg");

        this.storyEvent();
    },
    destroyed: function () {
        try {
            document.getElementsByTagName("html")[0].classList.remove("noScroll")
        } catch (error) { }

        let appDom = document.getElementById("app");
        appDom.classList.remove("introBg");
    },
    methods: {
        goHowToPlay() {
            this.dialogHtp = true;
            this.nowStory = 99;
        },
        eventHtp() {
            this.dialogHtp = false;
            this.$store.commit("clearRally0");
            this.$router.replace({ name: 'top' });
        },
        storyNext(isForce) {
            if (isForce != true && this.isLockStory == true) {
                console.log("lock")
                return;
            }
            this.isLockStory = true;
            clearTimeout(this.autoStoryTimer);
            this.nowStory++;
            this.$store.commit("setStoryNum", this.nowStory);
            this.storyEvent();
        },
        storyEvent() {
            let nextTiming = 500;
            let appDom = document.getElementById("app");
            switch (this.nowStory) {
                case 0:
                    this.msgName = "リムル";
                    this.msgText = "これで完成だ！";
                    break;
                case 1:
                    // appDom.classList.add("introBg");
                    this.autoStoryTimer = setTimeout(() => {
                        this.storyNext(true);
                    }, 1000);
                    nextTiming = 2000;
                    break;
                case 2:
                    this.msgName = "リムル";
                    this.msgText = "ここ1年間このために\nコツコツと準備してきたんだ。";
                    break;
                case 3:
                    this.msgName = "リムル";
                    this.msgText = "その名も\n「ユネッサン・テンペスト」";
                    break;
                case 4:
                    this.msgName = "リムル";
                    this.msgText = "テンペストの観光娯楽地区に";
                    break;
                case 5:
                    this.msgName = "リムル";
                    this.msgText = "オープンした\n温泉アミューズメントパーク。";
                    break;
                case 6:
                    this.msgName = "リムル";
                    this.msgText = "ここは、俺の理想を形にした\n温泉アミューズメントパークだ。";
                    break;
                case 7:
                    this.msgName = "リムル";
                    this.msgText = "早速最初のお客さんだ。\nでももう開業準備でヘトヘトだ…";
                    break;
                case 8:
                    this.msgName = "リムル";
                    this.msgText = "あ、そこの看板がずれている。";
                    break;
                case 9:
                    this.msgName = "リムル";
                    this.msgText = "さっきも直したような。";
                    break;
                case 10:
                    appDom.classList.remove("introBaseBg");
                    appDom.classList.remove("introBg");
                    break;
                default:
                    break;
            }
            this.lockStoryTimer = setTimeout(() => {
                this.isLockStory = false;
            }, nextTiming)
        }
    },
    computed: {
    },
    watch: {

    }
};
</script>

<template>
    <v-dialog v-model="isOpen" :max-width="dialogMaxWidth" persistent>
        <v-card>
            <v-card-text class="text-center">
                <p>
                    箱根小涌園ユネッサンが<br />
                    4月9日まで<br />
                    異世界への扉を<br />
                    開いているようです。<br />
                    参加してみることを<br />
                    お勧めします。<br />
                </p>
                <p>
                    もう一度最初から<br />
                    見ますか？
                </p>
            </v-card-text>
            <v-card-actions>
                <v-row class="pl-5 pr-5">
                    <v-col cols="6" class="text-center">
                        <v-btn @click="eventYes" class="btn_orange" width="100%">はい</v-btn>
                    </v-col>
                    <v-col cols="6" class="text-center">
                        <v-btn @click="eventNo" class="btn_purple" width="100%">いいえ</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>

    </v-dialog>
</template>
<style scoped src='@/assets/style/windowAkawaku.css'>

</style>
<style scoped>
.bigtext {
    font-size: 1.5em;
    line-height: 1.2em;
}
</style>
<script>

export default {
    data: () => ({
        isOpen: true,
        actionMode: "",
        dialogMaxWidth: 400,
    }),
    props: {},
    created: function () {
        if (this.dialogMaxWidth * 1.5 > window.innerHeight) {
            this.dialogMaxWidth = window.innerHeight / 1.6;
            this.dialogCloseBtnStyle = "width:min(8vw, 48px) !important";
        }
    },
    destroyed: function () {
    },
    methods: {
        eventYes() {
            this.actionMode = "yes";
            this.isOpen = false;
        },
        eventNo() {
            this.actionMode = "no";
            this.isOpen = false;
        }
    },
    computed: {
    },
    watch: {
        isOpen(newVal) {
            if (!newVal) {
                setTimeout(() => {
                    this.$emit('close', this.actionMode);
                }, 500);
            }
        }
    }
}
</script>
<template>
    <v-dialog v-model="isOpen" max-width="400">
        <v-card>
            <v-card-title>
                <span v-if="titleType == 'billing'">ユネッサンの受付で<br />参加コードを<br />読みとってください</span>
                <span v-else>QRコードを読んで下さい</span>
            </v-card-title>
            <v-card-text>
                <div class="billingMemo text-center" v-if="titleType == 'billing'">【入場料とは別に参加費がかかります】</div>
                <v-row class="text-center">
                    <v-col cols="12" class="pa-0 pt-10 pb-7 qrreadArea">
                        <canvas ref="capCamera" width="240px" height="240px" class="qrcam"></canvas>
                        <div class=qralertArea v-if="qrNoHit">
                            <div class="qralert">
                                このコードは違うようです
                            </div>
                        </div>
                    </v-col>
                </v-row>


                <!-- <button @click="pushTestBtn" style="font-size:4px;font-family: Arial;color:gray;border:1px solid gray;">
                    テスト用:{{ testPickupKey }}</button> -->

            </v-card-text>
            <v-card-actions>
                <v-btn v-on:mousedown.prevent="isOpen = false" v-on:touchstart.prevent="isOpen = false"
                    class="btn_purple">閉じる</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<style scoped src='@/assets/style/windowAkawaku.css'>

</style>
<style scoped>
.qrreadArea {
    position: relative;
}

.qralertArea {
    position: absolute;
    top: 240px;
    left: 0;
    width: 100%;
    text-align: center;
}

.qralert {
    margin: 0 auto;
    width: 220px;
    font-size: 0.8em;
    border-radius: 10px;
    background-color: white;
    color: red;
    padding: 5px;
    opacity: 0.7;
}

.qrcam {
    background-color: gray;
    background-image: url('@/assets/images/wakeupqrreader.jpg');
    background-size: cover;
}

.v-card__title,
.v-card__text {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.v-card__title {

    font-size: 1.3em !important;
    padding-top: 10px !important;
    font-family: 'Noto Sans JP', sans-serif !important;
    font-weight: bold !important;
}

.v-card__text {
    padding-bottom: 3px !important;
}

.billingMemo {
    font-family: 'Noto Sans JP', sans-serif !important;
    font-size: 0.85em;
    margin-bottom: 10px;
}
</style>
<script>

export default {
    data: () => ({
        isOpen: true,
        qrNoHit: false,
        qrMessageTimer: null,
        testPickupKey: ""
    }),
    props: {
        codeData: {
            type: Object,
            default: {}
        },
        titleType: {
            type: String,
            default: ""
        }
    },
    created: function () {
        this.viewQrReader();

        //-----------------------
        // for (let key in this.codeData) {
        //     this.testPickupKey = key;
        //     break;
        // }
        // console.log("codeData", this.testPickupKey, this.codeData);

        //-----------------------
    },
    destroyed: function () {
        this.$stamp.stopCamera();
    },
    methods: {
        async viewQrReader(count) {
            if (!count) count = 1;
            if (this.$refs.capCamera) {
                try {
                    await this.$stamp.qrCamera(this.$refs.capCamera, (event) => {
                        // console.log("readQr", event.data, this.codeData[event.data], this.codeData);
                        // 指定のQRを探す。見つからなければ無視してそのまま探し続ける。
                        if (this.codeData[event.data]) {
                            this.$stamp.stopCamera();
                            // this.isOpen = false;
                            // setTimeout(() => {
                            this.$emit('correct', event.data);
                            // }, 500);
                        } else {
                            clearTimeout(this.qrMessageTimer);
                            this.qrMessageTimer = setTimeout(() => {
                                this.qrNoHit = false;
                            }, 1000);
                            this.qrNoHit = true;
                        }
                    })
                } catch (error) {
                    console.error(error);
                    this.$router.replace({ name: 'error', params: { 'errormessage': "カメラが利用ができません。" } });
                }
            } else if (count < 5) {
                setTimeout(() => {
                    this.viewQrReader(count + 1)
                }, 300);
            }
        },
        // pushTestBtn() {
        //     this.$stamp.stopCamera();
        //     this.$emit('correct', this.testPickupKey);
        // }
    },
    computed: {

    },
    watch: {
        isOpen(newVal) {
            if (!newVal) {
                setTimeout(() => {
                    this.$emit('close');
                }, 500);
                this.$stamp.stopCamera();
            }
        }
    }
}
</script>
import { render, staticRenderFns } from "./QrWindow.vue?vue&type=template&id=32843444&scoped=true&"
import script from "./QrWindow.vue?vue&type=script&lang=js&"
export * from "./QrWindow.vue?vue&type=script&lang=js&"
import style0 from "@/assets/style/windowAkawaku.css?vue&type=style&index=0&id=32843444&prod&scoped=true&lang=css&"
import style1 from "./QrWindow.vue?vue&type=style&index=1&id=32843444&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32843444",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VCol,VDialog,VRow})

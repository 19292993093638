<template>
    <v-container class="baseStyle">
        <div class="vMiddle" v-if="nowStory == 0" @click="goNext">
            <div class="text-center" v-if="nowRallyNum == 1">
                <img src="/images/map/rally1.png" width="90%" />
                <p style="margin-top:2em">
                    まずは洞窟風呂にいるリムルと<br />
                    ヴェルドラに挨拶に行こう！</p>
            </div>
            <div class="text-center" v-if="nowRallyNum == 2">
                <img src="/images/map/rally2.png" width="90%" />
                <p style="margin-top:2em">
                    本体のソウエイを探し出して、<br />
                    スタンプを押してもらおう！</p>
            </div>
            <div class="text-center" v-else-if="nowRallyNum == 3">
                <img src="/images/map/rally3.png" width="90%" />
                <p style="margin-top:2em">
                    ミリムとたくさん会話をして<br />
                    シュナからスタンプを押してもらおう!</p>
            </div>
            <div class="text-center" v-else-if="nowRallyNum == 4">
                <img src="/images/map/rally4.png" width="90%" />
                <p style="margin-top:2em">
                    案内された場所をめぐり、<br />
                    スタンプを押してもらおう!</p>
            </div>
            <div class="text-center" v-else-if="nowRallyNum == 5">
                <img src="/images/map/rally5.png" width="90%" />
            </div>
        </div>


        <v-row v-if="nowStory == 1">
            <v-col cols="12" class="ma-0 pa-0">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
                    height="100%" viewBox="0 0 480 840">
                    <g transform="translate(0 0)">
                        <image width="480" height="840" xlink:href="/images/map/map.png"></image>
                    </g>

                    <g transform="translate(273 201)" v-if="nowRallyNum == 1">
                        <g transform="translate(0 65)" class="mark">
                            <path d="M11.855,0,0,16.545,11.855,33.089H110.89l11.854-16.544L110.89,0Z" fill="#251e1c" />
                            <path d="M12.625,31.589,1.846,16.545,12.625,1.5h97.494L120.9,16.545,110.119,31.589Z"
                                fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1" />
                            <text x="61" y="22" text-anchor="middle" fill="#FFFFFF" font-family="M PLUS Rounded 1c"
                                font-size="14">
                                洞窟風呂
                            </text>
                        </g>
                        <image x="40" width="42" height="53" xlink:href="/images/map/map_point.png" class="mark">
                        </image>
                    </g>

                    <g transform="translate(263 278)" v-if="nowRallyNum == 2 && viewPoint1">
                        <g transform="translate(-70 -78)" class="mark">
                            <path d="M11.855,0,0,16.545,11.855,33.089H130.732l11.855-16.544L130.732,0Z"
                                fill="#251e1c" />
                            <path d="M12.625,31.59,1.846,16.545,12.625,1.5H129.961L140.74,16.545,129.961,31.59Z"
                                fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1" />

                            <text x="71" y="22" text-anchor="middle" fill="#FFFFFF" font-family="M PLUS Rounded 1c"
                                font-size="14">
                                本格コーヒー風呂
                            </text>
                        </g>
                        <image x="-21" y="-53" width="42" height="53" xlink:href="/images/map/map_point.png"
                            class="mark">
                        </image>
                    </g>
                    <g transform="translate(133 338)" v-if="nowRallyNum == 2 && viewPoint2">
                        <g transform="translate(-60 12)" class="mark">
                            <path d="M11.855,0,0,16.545,11.855,33.089H110.89l11.854-16.544L110.89,0Z" fill="#251e1c" />
                            <path d="M12.625,31.589,1.846,16.545,12.625,1.5h97.494L120.9,16.545,110.119,31.589Z"
                                fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1" />
                            <text x="61" y="22" text-anchor="middle" fill="#FFFFFF" font-family="M PLUS Rounded 1c"
                                font-size="14">
                                ワイン風呂
                            </text>
                        </g>
                        <image x="-21" y="-53" width="42" height="53" xlink:href="/images/map/map_point.png"
                            class="mark">
                        </image>
                    </g>
                    <g transform="translate(308 358)" v-if="nowRallyNum == 2 && viewPoint3">
                        <g transform="translate(-90 22)" class="mark">
                            <path d="M11.855,0,0,16.545,11.855,33.089H176.087l11.854-16.544L176.087,0Z"
                                fill="#251e1c" />
                            <path d="M12.625,31.589,1.846,16.544,12.625,1.5H175.316L186.1,16.544l-10.78,15.045Z"
                                fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1" />
                            <text x="94" y="22" text-anchor="middle" fill="#FFFFFF" font-family="M PLUS Rounded 1c"
                                font-size="14">
                                ボザッピィの湯ゥ遊広場
                            </text>
                        </g>
                        <image x="-21" y="-53" width="42" height="53" xlink:href="/images/map/map_point.png"
                            class="mark">
                        </image>
                    </g>

                    <g transform="translate(195 406)" v-if="nowRallyNum == 3">
                        <g transform="translate(-63 12)" class="mark">
                            <path d="M11.855,0,0,24.037,11.855,48.074H110.89l11.854-24.037L110.89,0Z" fill="#251e1c" />
                            <path d="M12.787,46.574,1.673,24.037,12.787,1.5h97.17l11.114,22.537L109.957,46.574Z"
                                fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1" />
                            <text x="62" y="22" text-anchor="middle" fill="#FFFFFF" font-family="M PLUS Rounded 1c"
                                font-size="14">
                                フォンターナ
                            </text>
                            <text x="62" y="38" text-anchor="middle" fill="#FFFFFF" font-family="M PLUS Rounded 1c"
                                font-size="12">
                                （フードコート）
                            </text>
                        </g>
                        <image x="-21" y="-53" width="42" height="53" xlink:href="/images/map/map_point.png"
                            class="mark">
                        </image>
                    </g>

                    <g transform="translate(154 208)" v-if="nowRallyNum == 4 && viewPoint1">
                        <g transform="translate(0 68)" class="mark">
                            <path d="M11.855,0,0,16.545,11.855,33.089H110.89l11.854-16.544L110.89,0Z" fill="#251e1c" />
                            <path d="M12.625,31.589,1.846,16.545,12.625,1.5h97.494L120.9,16.545,110.119,31.589Z"
                                fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1" />
                            <text x="61" y="22" text-anchor="middle" fill="#FFFFFF" font-family="M PLUS Rounded 1c"
                                font-size="14">
                                緑茶風呂
                            </text>
                        </g>
                        <image x="40" width="42" height="53" xlink:href="/images/map/map_point.png" class="mark">
                        </image>
                    </g>
                    <g transform="translate(229 245)" v-if="nowRallyNum == 4 && viewPoint2">
                        <g transform="translate(0 88)" class="mark">
                            <path d="M11.855,0,0,16.545,11.855,33.089H110.89l11.854-16.544L110.89,0Z" fill="#251e1c" />
                            <path d="M12.625,31.589,1.846,16.545,12.625,1.5h97.494L120.9,16.545,110.119,31.589Z"
                                fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1" />
                            <text x="61" y="22" text-anchor="middle" fill="#FFFFFF" font-family="M PLUS Rounded 1c"
                                font-size="14">
                                緑のテラス
                            </text>
                        </g>
                        <image x="40" width="42" height="53" xlink:href="/images/map/map_point.png" class="mark">
                        </image>
                    </g>

                    <g transform="translate(222 480)" v-if="nowRallyNum == 5">
                        <g transform="translate(-90 32)" class="mark">
                            <path d="M11.855,0,0,16.545,11.855,33.089H176.087l11.854-16.544L176.087,0Z"
                                fill="#251e1c" />
                            <path d="M12.625,31.589,1.846,16.544,12.625,1.5H175.316L186.1,16.544l-10.78,15.045Z"
                                fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1" />
                            <text x="94" y="22" text-anchor="middle" fill="#FFFFFF" font-family="M PLUS Rounded 1c"
                                font-size="14">
                                リラクゼーションルーム
                            </text>
                        </g>
                        <image x="-21" y="-53" width="42" height="53" xlink:href="/images/map/map_point.png"
                            class="mark">
                        </image>
                    </g>


                </svg>
            </v-col>
        </v-row>
        <div class="msgArea" v-if="nowStory == 1">
            <div @click="storyNext">
                <MessageWindow :text="'QRコードを見つけたら\nタップしよう！'" :lock="false" :bgcolor="'#960000'"></MessageWindow>
            </div>
        </div>

        <QrWindow v-if="dialogQrScan" :codeData="qrcodeData" @close="dialogQrScan = false" @correct="eventReadQrData">
        </QrWindow>
    </v-container>
</template>

<style scoped>
.baseStyle {
    position: relative;
    padding: 0 !important;
    width: 100%;
    max-width: 480px;
}

.vMiddle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    widows: 100vw;
}

.msgArea {
    position: fixed;
    width: 100%;
    padding: 0 3vw;
    bottom: 30px;
    left: 0;
    text-align: center;
    z-index: 1;
}

.msgArea>div {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
}

.mark {
    animation: flash 2s linear infinite;
}

@keyframes flash {

    0% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    95% {
        opacity: 0.1;
    }

    100% {
        opacity: 1;
    }
}
</style>
<script>
import QrWindow from "./components/QrWindow";
import MessageWindow from "./components/MessageWindow";

export default {
    components: {
        QrWindow,
        MessageWindow,
    },
    data: () => ({
        dialogQrScan: false,
        qrcodeData: {},
        nowStory: 0,
        nowRallyNum: 0,
        viewPoint1: true,
        viewPoint2: true,
        viewPoint3: true,
    }),
    mounted: async function () {
        try {
            document.getElementsByTagName("html")[0].classList.add("noScroll")
        } catch (error) { }

        let appDom = document.getElementById("app");
        appDom.classList.add("mapBg");

        let nowRally = this.$store.getters.nowPlayRally;
        this.nowRallyNum = Number(nowRally.num);
        const qrcodeDataBase = this.$store.getters.code[this.nowRallyNum];
        this.qrcodeData = {};
        for (let key in qrcodeDataBase) {
            this.qrcodeData[key] = qrcodeDataBase[key];
        }

        if (this.nowRallyNum == 2) {
            // console.log(nowRally);

            if (nowRally.data[0] && nowRally.data[1] && nowRally.data[2]) {
                this.$router.replace({ name: 'rally2' });
            } else {
                // 利用済みQRを削除する
                for (let key in this.qrcodeData) {
                    if (nowRally.data[this.qrcodeData[key].num] == true) {
                        // console.log("del", this.qrcodeData[key].num);
                        if (this.qrcodeData[key].num == 0) {
                            this.viewPoint1 = false;
                        } else if (this.qrcodeData[key].num == 1) {
                            this.viewPoint2 = false;
                        } else if (this.qrcodeData[key].num == 2) {
                            this.viewPoint3 = false;
                        }
                        delete this.qrcodeData[key];

                    }
                }

                // console.log("QR LIST 2", this.qrcodeData)
            }

            if (nowRally.data[0] || nowRally.data[1] || nowRally.data[2]) {
                this.nowStory = 1;
            }

        } else if (this.nowRallyNum == 4) {
            if (nowRally.data[1] && nowRally.data[2]) {
                this.$router.replace({ name: 'rally4' });
            } else {
                if (nowRally.data[0] == false) {
                    this.nowStory = 0;
                } else {
                    this.nowStory = 1;
                }
                // 利用済みQRを削除する
                for (let key in this.qrcodeData) {
                    console.log(key, this.qrcodeData[key]);
                    if (nowRally.data[this.qrcodeData[key].num] == true) {
                        if (this.qrcodeData[key].num == 2) {
                            this.viewPoint1 = false;
                        } else if (this.qrcodeData[key].num == 1) {
                            this.viewPoint2 = false;
                        }
                        delete this.qrcodeData[key];
                    }
                }
            }

        }
    },
    destroyed: function () {
        try {
            document.getElementsByTagName("html")[0].classList.remove("noScroll")
        } catch (error) { }

        let appDom = document.getElementById("app");
        appDom.classList.remove("mapBg");
    },
    methods: {
        eventReadQrData(data) {
            // console.log("eventReadQrData", data);
            this.dialogQrScan = false;
            const hitData = this.qrcodeData[data];
            // console.log("hitdata", hitData, this.nowStory)

            if (hitData) {
                switch (this.nowRallyNum) {
                    case 1:
                        this.goNext();
                        break;
                    case 2:
                        this.$store.commit("checkRally" + hitData.id);
                        this.goNext();
                        break;
                    case 3:
                        this.goNext();
                        break;
                    case 4:
                        this.$store.commit("checkRally" + hitData.id);
                        this.goNext();
                        break;
                    case 5:
                        this.goNext();
                        break;
                    default:
                        break;
                }
            }
        },
        storyNext() {
            if (this.nowStory == 0) {
                this.nowStory++;
            } else if (this.nowStory == 1) {
                this.dialogQrScan = true;
            }
        },
        goNext() {
            // console.log("NEXT", this.nowRallyNum, this.nowStory);

            if (this.nowStory == 0) {
                if (this.nowRallyNum == 4) {
                    this.$router.replace({ name: 'rally4intro' });
                } else {
                    this.nowStory = 1;
                }
            } else {
                let nowRally;
                switch (Number(this.nowRallyNum)) {
                    case 1:
                        this.$store.commit("checkRally1Qr");
                        this.$router.replace({ name: 'rally1' });
                        break;
                    case 2:
                        nowRally = this.$store.getters.nowPlayRally;
                        if (nowRally.data[0] && nowRally.data[1] && nowRally.data[2]) {
                            this.$router.replace({ name: 'rally2' });
                        } else {
                            this.$router.replace({ name: 'rally2next' });
                        }
                        break;
                    case 3:
                        this.$store.commit("checkRally3Qr");
                        this.$router.replace({ name: 'rally3' });
                        break;
                    case 4:
                        nowRally = this.$store.getters.nowPlayRally;
                        if (nowRally.data[1] && nowRally.data[2]) {
                            this.$router.replace({ name: 'rally4' });
                        } else {
                            this.$router.replace({ name: 'rally4next' });
                        }
                        break;
                    case 5:
                        this.$store.commit("checkRally5Qr");
                        this.$router.replace({ name: 'rally5' });
                        break;
                    default:
                        break;
                }


            }


        },
    },
    computed: {
    },
    watch: {

    }
};
</script>

<template>
    <v-dialog v-model="isOpen" :max-width="dialogMaxWidth" persistent>
        <v-card>
            <v-card-title class="titleFont">お楽しみいただくに<br />当たって</v-card-title>
            <v-card-text class="textMain">
                <ul>
                    <li>このコンテンツはLINEと連携しながら進行を保存しています。必ずLINE内ブラウザを使用してお楽しみください。</li>
                    <li>コンテンツの進行で利用するQRコードの読み取りやカメラ撮影を利用するため、カメラの利用は許諾をお願いいたします。</li>
                </ul>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="windowClose" class="btn_orange">了解しました</v-btn>
            </v-card-actions>
        </v-card>

    </v-dialog>
</template>
<style scoped src='@/assets/style/windowAkawaku.css'>

</style>
<style scoped>
li {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.titleFont {
    font-family: 'Noto Sans JP', sans-serif !important;
    font-weight: bold !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.textMain {
    font-family: 'Noto Sans JP', sans-serif !important;
    font-size: 16px;
    line-height: 22px;
    padding-left: 12px !important;
    padding-right: 12px !important;
}
</style>
<script>

export default {
    data: () => ({
        isOpen: true,
        dialogMaxWidth: 400,
    }),
    props: {},
    created: function () {
        if (this.dialogMaxWidth * 1.5 > window.innerHeight) {
            this.dialogMaxWidth = window.innerHeight / 1.6;
            this.dialogCloseBtnStyle = "width:min(8vw, 48px) !important";
        }
    },
    destroyed: function () {
    },
    methods: {
        windowClose() {
            this.isOpen = false;
        }
    },
    computed: {
    },
    watch: {
        isOpen(newVal) {
            if (!newVal) {
                this.$emit('close', this.data);
            }
        }
    }
}
</script>
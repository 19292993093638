<template>
  <v-container class="baseStyle">

    <v-row>
      <v-col cols="12" class="text-center ma-0 pa-0">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%"
          viewBox="0 0 854 1841">
          <g transform="translate(0 0)">
            <image width="854" height="1841" xlink:href="/images/top/main.jpg"></image>
          </g>
          <g transform="translate(0 0)" :class="modeView == 0 ? 'norenAnime' : ''">
            <image width="854" height="166" xlink:href="/images/top/noren.png"></image>
          </g>

          <g transform="translate(560 1204)" @click="viewHowToPlay">
            <image width="220" height="220" xlink:href="/images/top/btn_htp.png"></image>
          </g>

          <g v-if="modeView == 2">

            <g transform="translate(261 1070)" @click="goPresent">
              <image width="332" height="332" xlink:href="/images/top/btn_main_clear.png"></image>
            </g>
            <g transform="translate(261 1070)" @click="goPresent" v-if="isClearTiming">
              <image width="332" height="332" xlink:href="/images/top/btn_main_next.png" class="changeBeforBtn"></image>
            </g>
            <g transform="translate(161 970)" @click="goPresent" v-if="isClearTiming">
              <image width="532" height="532" xlink:href="/images/top/btn_flash.png" class="changeLightMain"></image>
            </g>

            <g transform="translate(78 1204)" @click="viewTegata">
              <image width="220" height="220" xlink:href="/images/top/btn_meister.png"></image>
            </g>
            <g transform="translate(78 1204)" @click="viewTegata" v-if="isUpdateTegata">
              <image width="220" height="220" xlink:href="/images/top/btn_tegata.png" class="changeBeforBtn"></image>
            </g>
            <g transform="translate(19 1133)" @click="viewTegata" v-if="isUpdateTegata">
              <image width="350" height="350" xlink:href="/images/top/btn_flash.png" class="changeLightLeft"></image>
            </g>

          </g>

          <g v-else-if="modeView == 1">
            <g transform="translate(261 1070)" @click="goNext">
              <image width="332" height="332" xlink:href="/images/top/btn_main_next.png"></image>
            </g>


            <g v-if="isMeister">
              <g transform="translate(78 1204)" @click="viewTegata">
                <image width="220" height="220" xlink:href="/images/top/btn_meister.png"></image>
              </g>
              <g transform="translate(78 1204)" @click="viewTegata" v-if="isUpdateTegata">
                <image width="220" height="220" xlink:href="/images/top/btn_tegata.png" class="changeBeforBtn"></image>
              </g>
              <g transform="translate(19 1133)" @click="viewTegata" v-if="isUpdateTegata">
                <image width="350" height="350" xlink:href="/images/top/btn_flash.png" class="changeLightLeft"></image>
              </g>
            </g>
            <g transform="translate(78 1204)" @click="viewTegata" v-else-if="nowRallyNum > 1">
              <image width="220" height="220" xlink:href="/images/top/btn_tegata.png"></image>
            </g>
            <g transform="translate(78 1204)" v-else>
              <image width="220" height="220" xlink:href="/images/top/btn_none.png"></image>
            </g>
          </g>

          <g v-else>
            <g transform="translate(261 1070)" @click="goNext">
              <image width="332" height="332" xlink:href="/images/top/btn_main_start.png"></image>
            </g>
            <g transform="translate(78 1204)">
              <image width="220" height="220" xlink:href="/images/top/btn_none.png"></image>
            </g>
          </g>


        </svg>
      </v-col>
    </v-row>

    <v-row class="text-center ma-0 pa-10 infoText">
      <v-col cols="12" class="text-center ma-0 pa-0 mb-3" @click="maintenanceCheck(1)" :style="maintenanceStyle">
        <h1>コンテンツを<br />
          お楽しみいただくにあたって</h1>
      </v-col>
      <v-col cols="12" class="text-left ma-0 pa-0" @click="maintenanceCheck(2)">
        <ul>
          <li>●LINEと連携して進行を保存しています。必ずLINE内ブラウザを使用してお楽しみいただきますようお願いいたします。</li>
          <li>●QRコードの読み取りやカメラ撮影を利用するため、カメラの利用は許諾をお願いいたします。</li>
          <li>●本コンテンツご利用時のデータ通信量（パケット）はお客様負担となります。通信についての契約確認のうえでご利用ください。</li>
          <li>●OSとLINEのバージョンは最新版を推奨しております。iPadでは参加できない場合がございます。</li>
          <li>●歩きながらのスマートフォンの使用は危険です。スマートフォン等の操作は周囲の安全を確認し、止まってご利用ください。</li>
        </ul>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-10 mt-15" v-if="modeView <= 1">
      <v-col cols="12" class="text-center ma-0 pa-0 ">
        <v-btn @click="goNext" class="btn_orange">湯めぐりをはじめる</v-btn>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-10 mt-15">
      <v-col cols="12" class="text-center">
        <v-img @click="goLink('https://www.ten-sura.com')" src="/images/top/banner1.png"></v-img>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-img @click="goLink('https://www.yunessun.com/')" src="/images/top/banner2.png"></v-img>
      </v-col>
    </v-row>
    <v-row class="ma-0 mt-15 pa-0">
      <v-col cols="12" class="mb-0 pb-0 text-center">
        <img src="/images/top/top_koushiki.png" width="50%" />
      </v-col>
      <v-col cols="12" class="mt-0 pt-3 text-center" style="color:black;font-size: 11px;">
        ©川上泰樹・伏瀬・講談社／転スラ製作委員会
      </v-col>
    </v-row>

    <HowtoPlayWindow v-if="dialogHtp" @close="dialogHtp = false"></HowtoPlayWindow>

  </v-container>
</template>

<style scoped>
.baseStyle {
  /* display: flex;
  align-items: center;
  flex-direction: column; */
  padding: 0 !important;
  width: 100%;
  max-width: 480px;
}

.infoText {
  color: black;
}

h1 {
  font-size: 1.3em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
  margin: 0;
  padding: 0.5em 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.norenAnime {
  transform: translateY(-200px);
  animation-name: slideIn;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

@keyframes slideIn {
  0% {
    transform: translateY(-200px);
  }

  100% {
    transform: translateY(0px);
  }
}

.changeBeforBtn {
  opacity: 1;
  animation-name: fadeOut;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  5% {
    filter: brightness(200%) blur(2px);
  }

  30% {
    filter: brightness(100%);
  }

  60% {
    opacity: 1;
    filter: brightness(100%) blur(10px);
  }

  100% {
    filter: brightness(100%) blur(10px);
    opacity: 0;
  }
}

.changeLightLeft {
  opacity: 0;
  animation-name: flashLight;
  animation-delay: 1s;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  transform-origin: 175px 175px;
}

.changeLightMain {
  opacity: 0;
  animation-name: flashLight;
  animation-delay: 1s;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  transform-origin: 266px 266px;
}

@keyframes flashLight {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
    transform: rotate(45deg);
  }

  40% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

svg {
  will-change: filter;
}

.btnPinkAnime {
  animation: growPink 4s 0s ease infinite;
  /* animation: fadeIn 2s 1s linear 1, growPink 4s 3s ease infinite; */
}

@keyframes growPink {
  50% {
    filter: drop-shadow(0px 0px 30px rgb(255, 0, 255)) brightness(165%);
    transform: translateY(-5px);
  }

  0%,
  100% {
    filter: none;
    transform: translateY(0px);
  }
}
</style>
<script>
import HowtoPlayWindow from "./components/HowtoPlayWindow";

export default {
  name: "Top",
  data: () => ({
    dialogHtp: false,
    modeView: 0,
    nowRallyNum: 0,
    isMeister: false,
    maintenanceCisMeisterunt: 0,
    maintenanceStyle: "",
    isUpdateTegata: false,
    isClearTiming: false
  }),
  components: {
    HowtoPlayWindow,
  },

  mounted: async function () {
    try {
      document.getElementsByTagName("html")[0].classList.remove("noScroll")
    } catch (error) { }

    this.maintenanceCount = 0;

    let appDom = document.getElementById("app");
    appDom.classList.add("topBg");
    let nowRally = this.$store.getters.nowPlayRally;

    this.nowRallyNum = nowRally.num;
    try {
      if (nowRally.allclear) {
        this.modeView = 2;
      } else if (nowRally.num != 1 || (nowRally.num == 1 && nowRally.data[0] == true)) {
        this.modeView = 1;
        if (nowRally.data[0] && nowRally.data[1]) {
          console.log("MISTTT")
          this.isMeister = true;
        }
      } else {
        this.modeView = 0;
      }
    } catch (error) {
      console.error(error);
      this.modeView = 0;
    }



    if (!this.$store.getters.isCheckDevice) {
      try {
        await this.$stamp.checkUseDevice();
        this.$store.commit("setCheckDevice", true);
      } catch (error) {
        this.$router.replace({ name: 'error', params: { 'errormessage': "カメラなどのデバイスの利用ができません。" } });
      }
    }

    if (this.$store.getters.isClearTiming) {
      this.$store.commit("setClearTiming", false);
      this.isClearTiming = true;
    }
    if (this.$store.getters.isUpdateTegata) {
      this.$store.commit("setUpdateTegata", false);
      this.isUpdateTegata = true;
    }

  },
  destroyed: function () {
    let appDom = document.getElementById("app");
    appDom.classList.remove("topBg");
  },
  methods: {
    goNext() {
      let nowRally = this.$store.getters.nowPlayRally;
      let nextName = "rally";

      if (nowRally.num == 2 && nowRally.data[0] && nowRally.data[1] && nowRally.data[2]) {
        nextName += nowRally.num;
      } else if (nowRally.num == 4 && nowRally.data[0] && nowRally.data[1]) {
        nextName += nowRally.num;
      } else if (nowRally.num != 2 && nowRally.num != 4 && nowRally.data[0]) {
        nextName += nowRally.num;
      } else {
        nextName += "map";
      }
      // if (!nowRally.data[0] || nowRally.num == 4) {
      //   nextName += "map";
      // } else {
      //   nextName += nowRally.num;
      // }
      console.log("NEXT", nextName, nowRally);
      this.$router.replace({ name: nextName });
    },
    goPresent() {
      console.log("Present");
      this.$router.replace({ name: 'present' });
    },
    goLink(url) {
      window.open(url, '_blank');
    },
    viewTegata() {
      console.log("Tegata");
      this.$router.replace({ name: 'tegata' });
    },
    viewHowToPlay() {
      console.log("HTP");
      this.dialogHtp = true;
    },
    maintenanceCheck(num) {
      if (num == 0) {
        this.maintenanceCount = 0;
      } else if (num == 1 && this.maintenanceCount % 4 < 2) {
        this.maintenanceCount++;
      } else if (num == 2 && this.maintenanceCount % 4 >= 2) {
        this.maintenanceCount++;
      } else {
        this.maintenanceCount = 0;
      }
      // console.log(num, this.maintenanceCount, this.maintenanceCount % 4)
      if (this.maintenanceCount == 6) {
        console.log("maintenance mode on");
        this.$store.commit("setMaintenance", true);
        this.maintenanceCount = 0;
        this.$router.push({ name: 'maintenance' });
      }

      let c = ["#000000", "#000000", "#666666", "#999999", "#AAAAAA", "#CCCCCC"][this.maintenanceCount];
      console.log(num, this.maintenanceCount, c)
      this.maintenanceStyle = "color:" + c;
    }
  },

  computed: {

  },
  watch: {

  }
};
</script>

<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" class="mt-15">
        <img src="/images/top/btn_main_clear.png" width="203" height="203" />
      </v-col>
    </v-row>

    <v-row class="text-center mt-5">
      <v-col cols="12" class="pa-0 ma-0">
        リムル達と記念撮影！
      </v-col>
      <v-col cols="12" class="pa-0 ma-0"
        style="font-size:1.5em;font-weight: bold;font-family:'Noto Sans JP', sans-serif;">
        フォトフレーム
      </v-col>
      <v-col cols="12" class="pt-5 pb-5">
        <div class="photoframe text-center">
          <img src="/images/present/frame_sample.jpg" width="80%" style="border:4px solid #ffffff;margin:0 auto;">
        </div>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn class="btn_orange" @click="viewDialogShot = true">写真を撮る</v-btn>
      </v-col>
    </v-row>

    <v-row class="text-center pt-15 mt-15">
      <v-col cols="12" class="pa-0 ma-0">
        湯めぐりの思い出
      </v-col>
      <v-col cols="12" class="pa-0 ma-0"
        style="font-size:1.5em;font-weight: bold;font-family:'Noto Sans JP', sans-serif;">
        音声&映像アーカイブ
      </v-col>
      <v-col cols="12" class="pa-0 ma-0 mt-7">
        ー 入浴しに行きますか？ ー
      </v-col>
      <v-col cols="12" class="pl-3 pr-3">
        <video src="/images/start/start.mp4" poster="/images/start/poster.jpg" width="100%" controls playsinline
          disablePictureInPicture controlsList="nodownload noremoteplayback" oncontextmenu="return false;"></video>
      </v-col>
      <v-col cols="12" class="pa-0 ma-0 mt-7">
        ー 湯めぐり手形の進化 ー
      </v-col>
      <v-col cols="12" class="pl-3 pr-3">
        <video src="/images/rally5/ending.mp4" poster="/images/rally5/poster.jpg" width="100%" ref="endingMovie"
          controls playsinline disablePictureInPicture controlsList="nodownload noremoteplayback"
          oncontextmenu="return false;"></video>
      </v-col>
      <v-col cols="12" class="pa-0 ma-0 mt-7">
        ー リムルからのメッセージ ー
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn class="btn_orange" @click="goRally5">振り返る</v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-15 pt-15 mb-15 pa-0 ma-0 bottomSpace">
      <v-col cols="12" class="text-center pl-9 pr-9">
        <v-img @click="goLink('https://www.ten-sura.com')" src="/images/top/banner1.png"></v-img>
      </v-col>
      <v-col cols="12" class="text-center mb-15 pb-15">
        <v-btn class="btn_orange" @click="goTop">TOPに戻る</v-btn>
      </v-col>
    </v-row>


    <ShotWindow v-if="viewDialogShot" @close="viewDialogShot = false"></ShotWindow>
  </v-container>
</template>

<style scoped>
.bottomSpace {
  margin-bottom: 450px !important;
}
</style>
<script>
import axios from "axios";

import ShotWindow from "./components/ShotWindow";


export default {
  components: {
    ShotWindow,
  },
  data: () => ({
    viewDialogShot: false,
  }),
  created: function () {

  },
  mounted: function () {
    try {
      document.getElementsByTagName("html")[0].classList.remove("noScroll")
    } catch (error) { }

    let nowRally = this.$store.getters.nowPlayRally;
    if (nowRally.allclear == false) {
      this.$router.replace({ name: 'top' });
    }
    let appDom = document.getElementById("app");
    appDom.classList.add("presentBg");
  },

  destroyed: function () {
    let appDom = document.getElementById("app");
    appDom.classList.remove("presentBg");
  },
  methods: {
    goTop() {
      this.$router.replace('/top');
    },
    goRally5() {
      this.$router.replace('/rally5');
    },
    goLink(url) {
      window.open(url, '_blank');
    },
  },
  watch: {

  }
};
</script>


<template>
  <v-app class="defaultBg">
    <v-main>
      <router-view />
      <div class="onepointArea" v-if="viewOnepoint" @click="goTop">
        <img src="/images/common/logo_yu.png" />
      </div>
    </v-main>
    <!-- <v-footer padless color="transparent"> -->
    <!-- <v-col class="text-center pt-5 pb-10 footertext" cols="12" @click="deleteUser">
        &copy; 転スラ製作委員会
      </v-col> -->
    <!-- </v-footer> -->
  </v-app>
</template>
<style>

</style>
<style scoped>
.footertext {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: max(1vw, 12px);
  font-weight: normal;
  color: gray;
}

.onepointArea {
  position: fixed;
  top: 0;
  right: 7vw;
  z-index: 9999;
  animation-name: slideIn;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  cursor: pointer;
}

.onepointArea img {
  width: 12vw;
  max-width: 53px;
}

@keyframes slideIn {
  0% {
    transform: translateY(-100px);
  }

  100% {
    transform: translateY(0px);
  }
}
</style>
<style src='@/assets/style/button.css'>

</style>
<script>
import axios from "axios";

export default {
  name: "App",

  data: () => ({
    viewOnepoint: false
  }),
  created: async function () {

    // param処理
    // this.$store.dispatch("initParam");

    this.$store.subscribe((mutation, state) => {

      if (!state.localUserHashKey) {
        console.log("☆NO SET USER", state);
        return;
      }
      const outEvents = ["setLineLocalUserHashKey", "setNowPlayRally", "setStoryNum", "setMaintenance", "setCheckDevice", "setUpdateTegata", "setClearTiming"];

      if (outEvents.indexOf(mutation.type) != -1) {
        // console.log("subscribe SKIP", mutation.type)
        return;
      } else {
        console.log("save local subscribe", mutation.type);
      }

      if (["init", "resetData"].indexOf(mutation.type) == -1) {
        let saveData = {
          "nowPiece": this.$store.getters.nowPiece,
          "checkTos": this.$store.getters.isCheckTos,
          "localUserHashKey": this.$store.getters.localUserHashKey,
          "lineUserHashKey": this.$store.getters.lineUserHashKey,
          "rally": this.$store.getters.rally,
          "userName": this.$store.getters.userName,
        }

        axios.post(
          process.env.VUE_APP_SERVER_SAVE_PATH + 'user',
          saveData)
          .then((res) => {
            console.log("☆ServerRes", res);
          })
          .catch((error) => {
            console.error("☆ServerERROR", error);
          });

        localStorage.setItem(this.$store.getters.hashPrefix + "_data", JSON.stringify(saveData));


        if (mutation.type.indexOf('clearRally') == 0) {
          let nowRally = mutation.type.substring(10);
          gtag('event', 'missionclear', { 'event_category': nowRally, 'event_label': undefined });
        } else if (mutation.type == "setUserName") {
          gtag('event', 'setname', { 'event_category': this.$store.getters.userName, 'event_label': undefined });
        } else if (mutation.type == "clearBilling") {
          gtag('event', 'billing', { 'event_category': undefined, 'event_label': undefined });
        }
        gtag('event', 'progress', { 'event_category': mutation.type, 'event_label': undefined });


      }

      let ckrallyNum = 0;

      console.log("DATA UPDATE");
      // 毎回内部データを再計算させる
      let notClear = false;
      for (ckrallyNum in this.$store.state.rally) {
        notClear = false;
        for (let j in this.$store.state.rally[ckrallyNum]) {
          if (!this.$store.state.rally[ckrallyNum][j]) {
            notClear = true;
            break;
          }
        }
        if (notClear) {
          break;
        }
      }
      let d = {
        "num": ckrallyNum,
        "allclear": !notClear,
        "data": state.rally[ckrallyNum]
      };
      this.$store.commit("setNowPlayRally", d);

    });

    if (this.$route.query.key == "yune123") {
      console.log("★ Maintenance Param")
      this.$store.commit("setMaintenance", true);
    }

    // 他のページが開いてたら強制移動
    if (!this.$store.getters.isLoad && this.nowPath != "/") {
      this.$router.replace({ name: 'loading' });
    }

  },
  methods: {
    goTop() {
      this.$router.replace('/top');
    },
  },
  computed: {

    nowPath() {
      return this.$route.path;
    }
  },
  watch: {
    nowPath(newVar) {
      console.log("App.vue", "★CHANGE PAGE", newVar);
      let appDom = document.getElementById("app");
      switch (newVar) {
        case "/rallymap":
        case "/rally1":
        case "/rally2":
        case "/rally3":
        case "/rally4":
        case "/rally4intro":
        case "/rally5":
        case "/tegata":
        case "/present":
          this.viewOnepoint = true;
          break;
        default:
          this.viewOnepoint = false;
          break;
      }
    }
  }
};
</script>

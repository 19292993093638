<template>
    <v-dialog v-model="isOpen" :max-width="dialogMaxWidth" persistent>
        <div>
            <v-card class="dialogBase dialogMain">
                <v-card-text>
                    {{ text }}
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="windowClose" class="btn_orange">はい</v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </v-dialog>
</template>
<style scoped>
.v-card {
    padding-top: 1vh;
    padding-bottom: 1vh;
    border-style: solid !important;
    border-width: 5px !important;
    border-color: black;
    border-radius: 5px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.v-card__text {
    padding: 20px 5px;
    text-align: center;
    color: black !important;
    font-size: 1em !important;
    font-weight: normal !important;
    white-space: pre-line;
}

.v-card__actions {
    display: flex;
    justify-content: center;
}
</style>
<script>

export default {
    data: () => ({
        isOpen: true,
        dialogMaxWidth: 300,
    }),
    props: {
        text: {
            type: String,
            default: ""
        },
    },
    created: function () {
        if (this.dialogMaxWidth * 1.5 > window.innerHeight) {
            this.dialogMaxWidth = window.innerHeight / 1.6;
            this.dialogCloseBtnStyle = "width:min(8vw, 48px) !important";
        }
    },
    destroyed: function () {
    },
    methods: {
        windowClose() {
            this.isOpen = false;
        }
    },
    computed: {
    },
    watch: {
        isOpen(newVal) {
            if (!newVal) {
                // 閉じるアニメーション用のdelay
                setTimeout(() => {
                    this.$emit('close', this.data);
                }, 500);
            }
        }
    }
}
</script>
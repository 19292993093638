<template>
    <v-container>
        <h1>maintenance</h1>
        <v-row>
            <v-col>
                <v-btn @click='actionReload'>再読み込み</v-btn>
            </v-col>
        </v-row>
        <h3>ユーザ情報</h3>
        <v-row>
            <v-col>
                <v-btn @click='deleteUser'>データ削除</v-btn>
            </v-col>
        </v-row>
        <h3>Rally0</h3>
        <v-row>
            <v-col>
                <v-btn @click='resetData("clearBilling");'>Billing OK</v-btn>
            </v-col>
            <v-col>
                <v-btn @click='resetData("clearRally0")'>Clear Rally0</v-btn>
            </v-col>
        </v-row>
        <h3>Rally1</h3>
        <v-row>
            <v-col>
                <v-btn @click='resetData("checkRally1Qr")'>QR OK</v-btn>
            </v-col>
            <v-col>
                <v-btn @click='resetData("clearRally1")'>Clear Rally1</v-btn>
            </v-col>
        </v-row>
        <h3>Rally2</h3>
        <v-row>
            <v-col>
                <v-btn @click='resetData("checkRally2Qr")'>QR OK</v-btn>
            </v-col>
            <v-col>
                <v-btn @click='resetData("clearRally2")'>Clear Rally2</v-btn>
            </v-col>
        </v-row>
        <h3>Rally3</h3>
        <v-row>
            <v-col>
                <v-btn @click='resetData("checkRally3Qr")'>QR OK</v-btn>
            </v-col>
            <v-col>
                <v-btn @click='resetData("clearRally3")'>Clear Rally3</v-btn>
            </v-col>
        </v-row>
        <h3>Rally4</h3>
        <v-row>
            <v-col>
                <v-btn @click='resetData("checkRally4Intro")'>Intro OK</v-btn>
            </v-col>
            <v-col>
                <v-btn @click='resetData("clearRally4")'>Clear Rally4</v-btn>
            </v-col>
        </v-row>
        <h3>Rally5</h3>
        <v-row>
            <v-col>
                <v-btn @click='resetData("checkRally5Qr")'>QR OK</v-btn>
            </v-col>
            <v-col>
                <v-btn @click='resetData("clearRally5")'>Clear Rally5</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped>
h1,
h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    border-top: 1px solid white;
}
</style>
<script>
import axios from "axios";

export default {
    data: () => ({
    }),
    created: async function () {
        if (!this.$store.getters.isMaintenance) {
            this.$router.replace({ name: 'loading' });
            return;
        }
    },
    mounted: function () {
        try {
            document.getElementsByTagName("html")[0].classList.remove("noScroll")
        } catch (error) { }
    },
    destroyed: function () {
    },
    methods: {
        actionReload() {
            location.reload();
        },
        deleteUser() {
            localStorage.removeItem(this.$store.getters.hashPrefix + "_key");
            localStorage.removeItem(this.$store.getters.hashPrefix + "_data");

            const delData = {
                lineUserHashKey: this.$store.getters.lineUserHashKey,
                "deletePassKey": "yunessan delete abcde1234",
                localUserHashKey: this.$store.getters.localUserHashKey
            }
            axios.delete(
                process.env.VUE_APP_SERVER_SAVE_PATH + 'user',
                { data: delData })
                .then((res) => {
                    // console.log("☆ServerRes", res);
                    location.reload(true);
                })
                .catch((error) => {
                    console.error("☆☆ServerERROR", error);
                });
        },
        resetData(key) {
            this.$store.commit("resetData");
            switch (key) {
                case 'clearRally5':
                    this.$store.commit("clearRally0");
                    this.$store.commit("clearRally5");
                    this.$store.commit("clearRally4");
                    this.$store.commit("clearRally3");
                    this.$store.commit("clearRally2");
                    this.$store.commit("clearRally1");
                    break;
                case 'checkRally5Qr':
                    this.$store.commit("checkRally5Qr");
                case 'clearRally4':
                    this.$store.commit("clearRally4");
                    this.$store.commit("clearRally3");
                    this.$store.commit("clearRally2");
                    this.$store.commit("clearRally1");
                    this.$store.commit("clearRally0");
                    break;
                case 'checkRally4Intro':
                    this.$store.commit("checkRally4Intro");
                case 'clearRally3':
                    this.$store.commit("clearRally3");
                    this.$store.commit("clearRally2");
                    this.$store.commit("clearRally1");
                    this.$store.commit("clearRally0");
                    break;
                case 'checkRally3Qr':
                    this.$store.commit("checkRally3Qr");
                case 'clearRally2':
                    this.$store.commit("clearRally2");
                    this.$store.commit("clearRally1");
                    this.$store.commit("clearRally0");
                    break;
                case 'checkRally2Qr':
                    this.$store.commit("checkRally2a");
                    this.$store.commit("checkRally2b");
                    this.$store.commit("checkRally2c");
                case 'clearRally1':
                    this.$store.commit("clearRally1");
                    this.$store.commit("clearRally0");
                    break;
                case 'checkRally1Qr':
                    this.$store.commit("checkRally1Qr");
                case 'clearRally0':
                    this.$store.commit("clearRally0");
                    break;
                case 'clearBilling':
                    this.$store.commit("clearBilling");
                    break;

                default:
                    break;
            }
        }
    },
    computed: {
    },
    watch: {
    }
};
</script>

<template>
    <v-container class="baseStyle">
        <v-row>
            <v-col cols="12" class="ma-0 pa-0">
                <tegata v-if="nowStory == 13" :name="$store.getters.userName" :animation="true" :stamp="3" :ura="false"
                    :receive="false">
                </tegata>

                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
                    height="100%" viewBox="0 0 428 980" v-else>
                    <g transform="translate(0 0)">
                        <image width="428" height="980" xlink:href="/images/rally3/bg.jpg"></image>
                    </g>
                    <g transform="translate(177 188)" v-if="nowStory >= 0 && nowStory <= 8">
                        <image width="283" height="604" xlink:href="/images/rally3/shuna.png"
                            :class="nowStory == 8 ? 'fadeOut' : ''"></image>
                    </g>
                    <g transform="translate(-30 210)" v-if="nowStory >= 0 && nowStory <= 8">
                        <image width="306" height="577" xlink:href="/images/rally3/mirimu.png"
                            :class="nowStory == 8 ? 'fadeOut' : ''"></image>
                    </g>
                    <g transform="translate(88 188)" v-if="nowStory >= 9 && nowStory <= 12">
                        <image width="283" height="604" xlink:href="/images/rally3/shuna.png" class="fadeIn">
                        </image>
                    </g>

                    <g transform="translate(71 192)" v-if="nowStory >= 1 && nowStory <= 3">
                        <image width="355" height="185" xlink:href="/images/rally3/koma1.png" class="koma1"></image>
                    </g>
                    <g transform="translate(0 385)" v-if="nowStory >= 2 && nowStory <= 3">
                        <image width="363" height="186" xlink:href="/images/rally3/koma2.png" class="koma2"></image>
                    </g>

                    <g transform="translate(88 218)" v-if="nowStory == 12">
                        <image width="71" height="71" xlink:href="/images/rally3/onpu.png" class="fadeIn">
                        </image>
                    </g>
                </svg>
            </v-col>
        </v-row>
        <div class="msgArea">
            <div @click="storyNext">
                <MessageWindow :name="msgName" :text="msgText" :lock="isLockStory" :bgcolor="msgBgColor">
                </MessageWindow>
            </div>
        </div>
    </v-container>
</template>

<style scoped>
.baseStyle {
    position: relative;
    padding: 0 !important;
    width: 100%;
    max-width: 480px;
}

.msgArea {
    position: fixed;
    width: 100%;
    padding: 0 3vw;
    bottom: 30px;
    left: 0;
    text-align: center;
    z-index: 1;
}

.msgArea>div {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
}


.koma1 {
    transform: translateX(427px);
    animation-name: slideLeftRight;
    animation-duration: 0.6s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
}

.koma2 {
    transform: translateX(-427px);
    animation-name: slideRightLeft;
    animation-duration: 0.6s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
}

.fadeIn {
    opacity: 0;
    animation-name: fadeIn;
    animation-delay: 0.5s;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
}

.fadeOut {
    opacity: 1;
    animation-name: fadeOut;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
}



@keyframes slideLeftRight {
    0% {
        transform: translateX(427px);
    }

    100% {
        transform: translateX(0px);
    }
}

@keyframes slideRightLeft {
    0% {
        transform: translateX(-427px);
    }

    100% {
        transform: translateX(0px);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
</style>
<script>
import MessageWindow from "./components/MessageWindow";
import Tegata from "./components/Tegata";

export default {
    components: {
        MessageWindow,
        Tegata
    },
    data: () => ({
        nowStory: 0,
        msgName: '',
        msgText: '',
        msgBgColor: '#000000',
        lockStoryTimer: undefined,
        isLockStory: false,
    }),
    mounted: async function () {
        try {
            document.getElementsByTagName("html")[0].classList.add("noScroll")
        } catch (error) { }

        let appDom = document.getElementById("app");
        appDom.classList.add("rally3Bg");
        this.nowStory = this.$store.getters.viewStoryNum;
        if (this.nowStory > 13) {
            this.nowStory = 0;
        }
        this.storyEvent();
    },
    destroyed: function () {
        try {
            document.getElementsByTagName("html")[0].classList.remove("noScroll")
        } catch (error) { }

        let appDom = document.getElementById("app");
        appDom.classList.remove("rally3Bg");
        appDom.classList.remove("tegataBg");
    },
    methods: {
        storyNext(isForce) {
            if (isForce != true && this.isLockStory == true) {
                console.log("lock")
                return;
            }
            this.isLockStory = true;
            this.nowStory++;
            if (this.nowStory < 13) {
                this.$store.commit("setStoryNum", this.nowStory);
            }
            this.storyEvent();
        },
        storyEvent() {
            let nextTiming = 500;
            // console.log(this.nowStory)
            clearTimeout(this.autoStoryTimer);

            let appDom = document.getElementById("app");

            switch (this.nowStory) {
                case 0:
                    this.msgName = "ミリム";
                    this.msgText = '早くご飯を作るのだ～！';
                    break;
                case 1:
                    this.msgName = "ミリム";
                    this.msgText = "ん？誰だこの\n魔王ミリムに喋りかけてくるのは？";
                    break;
                case 2:
                    this.msgName = "シュナ";
                    this.msgText = "リムル様のご客人の方ですね。\nようこそ。";
                    break;
                case 3:
                    this.msgName = "シュナ";
                    this.msgText = "ここは料理を作っているところでして、\nちょうどよかった！";
                    break;
                case 4:
                    this.msgName = "ミリム";
                    this.msgText = "なんだなんだぁ〜？\n面白そうなものを持っているな？";
                    break;
                case 5:
                    this.msgName = "ミリム";
                    this.msgText = "ほ〜、それは\n湯巡り手形というのだな、";
                    break;
                case 6:
                    this.msgName = "ミリム";
                    this.msgText = "リムルはそんな楽しそうなものを\nワタシに教えてくれなかったぞ…";
                    break;
                case 7:
                    this.msgName = "ミリム";
                    this.msgText = "よく見せるのだ！";
                    break;
                case 8:
                    this.msgName = "";
                    this.msgText = "…";
                    break;
                case 9:
                    this.msgName = "シュナ";
                    this.msgText = "ありがとうございます。";
                    break;
                case 10:
                    this.msgName = "シュナ";
                    this.msgText = "ミリム様のお相手をして\nいただいたおかげで";
                    break;
                case 11:
                    this.msgName = "シュナ";
                    this.msgText = "料理が完成しました。";
                    break;
                case 12:
                    this.msgName = "シュナ";
                    this.msgText = "お礼にその湯巡り手形に\n印を押しておきますね";
                    break;
                case 13:
                    this.msgBgColor = "#960000";
                    this.msgName = "";
                    this.msgText = "湯めぐり手形に\n３つ目のスタンプが押された！";
                    appDom.classList.remove("rally3Bg");
                    appDom.classList.add("tegataBg");

                    this.$store.commit("clearRally3");
                    break;
                case 14:
                    this.goNext();
                // break;
                default:
                    this.msgBgColor = "#000000";
                    this.msgName = "";
                    this.msgText = "";
                    break;
            }
            this.lockStoryTimer = setTimeout(() => {
                this.isLockStory = false;
            }, nextTiming)
        },
        async goNext() {
            this.$router.replace({ name: 'rallymap' });
        },
        eventConfOk() {
            this.dialogConf = false;
        },
        eventNameInputOk(name) {
            const fnames = ["テンペスト", "ジュラ", "ユネッサン"];
            let fname = fnames[Math.floor(Math.random() * fnames.length)];
            this.inputName = name;
            this.$store.commit("setUserName", name + "・" + fname);
            this.dialogNameInput = false;
            this.storyNext();
        }
    },
    computed: {
    },
    watch: {

    }
};
</script>

<template>
  <v-container>
    <div class="text-center pt-5">
      <br />
      <br />
      <div class="loadingmsg">読み込み中</div>
      <br />
      <div class="hollow-dots-spinner" style="margin:0 auto">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>
  </v-container>
</template>

<style scoped>
.loadingmsg {
  color: gray;
}

.hollow-dots-spinner,
.hollow-dots-spinner * {
  box-sizing: border-box;
}

.hollow-dots-spinner {
  height: 15px;
  width: calc(30px * 3);
}

.hollow-dots-spinner .dot {
  width: 15px;
  height: 15px;
  margin: 0 calc(15px / 2);
  border: calc(15px / 5) solid gray;
  border-radius: 50%;
  float: left;
  transform: scale(0);
  animation: hollow-dots-spinner-animation 1000ms ease infinite 0ms;
}

.hollow-dots-spinner .dot:nth-child(1) {
  animation-delay: calc(300ms * 1);
}

.hollow-dots-spinner .dot:nth-child(2) {
  animation-delay: calc(300ms * 2);
}

.hollow-dots-spinner .dot:nth-child(3) {
  animation-delay: calc(300ms * 3);

}

@keyframes hollow-dots-spinner-animation {
  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
<script>
import axios from "axios";

export default {
  created: async function () {
    let localHashKey;
    let lineHashKey;
    let newUser = false;
    try {
      localHashKey = localStorage.getItem(this.$store.getters.hashPrefix + "_key");
      if (!localHashKey) throw new Error("not localHashKey");
    } catch (error) {
      let ukey = this.$store.getters.hashPrefix + Math.floor(Math.random() * 100000) + "_" + (new Date()).getTime() + "_" + navigator.userAgent;
      localHashKey = this.$CryptoJS.SHA256(ukey).toString();
      newUser = true;
      localStorage.setItem(this.$store.getters.hashPrefix + "_key", localHashKey);
    }

    // LINEでのサーバデータ
    let getData = false;

    try {
      await liff.init({
        liffId: process.env.VUE_APP_LINE_LIFF_ID,
      });
      if (!liff.isInClient()) {
        throw new Error("not line client");
      }
      if (!liff.isLoggedIn()) {
        throw new Error("not login");
      }
      let profile = await liff.getProfile();
      console.log("☆LINE profile", profile);
      lineHashKey = this.$CryptoJS.SHA256("tensura2023" + profile.userId).toString();
      let res = await axios.get(process.env.VUE_APP_SERVER_SAVE_PATH + "user/line_" + lineHashKey);
      console.log("☆SERVER LINE RES", res);
      if (res.data.error) throw new Error("server error " + res.message);

      this.$store.commit("init", res.data.save);
      getData = true;
      gtag('event', 'device', { 'event_category': 'line', 'event_label': undefined });

    } catch (e) {
      console.log("☆LIFF ERROR", e, newUser);
      gtag('event', 'device', { 'event_category': 'browser', 'event_label': undefined });
    }

    // ローカルのサーバデータ
    if (!getData) {
      try {
        let res = await axios.get(process.env.VUE_APP_SERVER_SAVE_PATH + "user/local_" + localHashKey);
        console.log("☆SERVER BROWSER RES", res);
        if (res.data.error) throw new Error("server error " + res.message);
        this.$store.commit("init", res.data.save);
        getData = true;
      } catch (error) {
        console.log(error);
      }
    }

    // ローカルにあるデータ
    if (!getData) {
      try {
        if (!newUser) {
          let data = JSON.parse(localStorage.getItem(this.$store.getters.hashPrefix + "_data"));
          console.log("☆LOCAL DATA", data);
          this.$store.commit("init", data);
          getData = true;
        }
      } catch (error) {
        console.log(error);
      }
    }




    this.$store.commit("setLineLocalUserHashKey", {
      "lineUserHashKey": lineHashKey,
      "localUserHashKey": localHashKey
    });

    console.log("------", this.$store.getters.isClearRally0, this.$store.getters.isClearBilling)

    if (this.$store.getters.isMaintenance) {
      this.$router.replace('/maintenance?key=yune123');
    } else if (this.$store.getters.isClearRally0 == true) {
      this.$router.replace({ name: 'top' });
    } else if (this.$store.getters.isClearBilling == true) {
      this.$router.replace({ name: 'intro' });
    } else {
      this.$router.replace({ name: 'start' });
    }

  },
  mounted: function () {
    try {
      document.getElementsByTagName("html")[0].classList.remove("noScroll")
    } catch (error) { }
  },
  methods: {

  },
  computed: {

  }
}
</script>


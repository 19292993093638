<template>
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%"
            viewBox="0 0 368 119">
            <g>
                <rect width="368" height="118.585" :fill="bgcolor" />
            </g>

            <g transform="translate(7.958 7.958)">
                <rect width="2.324" height="2.363" transform="translate(0 33.022)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 31.841)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 29.478)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 28.296)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 25.933)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 24.752)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 22.389)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 21.208)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 18.844)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 17.663)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 15.3)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 14.119)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 11.756)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 10.574)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 8.212)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 7.03)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 4.667)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 3.486)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(3.487)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(4.649 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(6.972)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(8.134 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(10.458)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(11.62 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(13.945)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(15.106 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(17.43)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(18.592 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(20.916)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(22.078 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(24.402)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(25.564 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(27.888)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(29.05 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(31.374)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(32.536 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(34.86)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(36.022 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(38.346)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(39.508 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(41.832)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(42.994 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(45.318)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(46.48 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(48.804)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(49.966 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(52.29)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(53.452 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(55.776)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(56.938 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(59.262)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(60.424 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(62.748)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(63.91 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(66.234)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(67.396 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(69.72)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(70.882 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(73.206)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(74.368 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(76.692)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(77.854 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(80.178)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(81.34 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(83.664)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(84.826 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(87.15)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(88.312 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(90.636)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(91.798 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(94.122)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(95.284 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(97.608)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(98.77 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(101.093)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(102.256 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(104.58)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(105.741 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(108.066)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(109.228 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(111.551)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(112.714 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(115.037)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(116.199 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(118.524)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(119.685 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(122.009)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(123.172 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(125.495)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(126.657 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(128.981)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(130.143 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(132.467)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(133.629 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(135.953)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(137.115 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(139.439)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(140.601 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(142.925)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(144.087 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(146.411)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(147.573 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(149.897)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(151.059 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(153.383)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(154.545 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(156.869)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(158.031 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(160.354)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(161.517 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(163.841)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(165.003 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(167.327)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(168.489 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(170.813)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(171.975 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(174.299)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(175.461 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(177.785)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(178.947 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(181.271)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(182.433 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(184.757)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(185.919 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(188.243)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(189.405 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(191.729)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(192.891 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(195.215)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(196.377 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(198.701)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(199.863 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(202.187)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(203.349 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(205.673)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(206.835 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(209.159)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(210.321 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(212.645)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(213.807 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(216.131)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(217.293 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(219.616)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(220.779 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(223.103)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(224.264 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(226.589)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(227.751 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(230.074)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(231.237 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(233.561)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(234.722 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(237.047)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(238.209 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(240.532)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(241.695 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(244.018)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(245.18 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(247.505)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(248.666 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(250.99)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(252.153 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(254.476)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(255.638 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(257.962)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(259.124 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(261.448)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(262.61 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(264.934)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(266.096 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(268.42)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(269.582 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(271.906)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(273.068 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(275.392)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(276.554 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(278.878)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(280.04 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(282.364)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(283.526 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(285.85)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(287.012 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(289.336)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(290.498 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(292.822)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(293.984 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(296.308)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(297.47 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(299.794)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(300.956 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(303.28)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(304.442 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(306.766)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(307.928 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(310.252)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(311.414 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(313.738)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(314.9 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(317.224)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(318.386 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(320.71)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(321.872 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(324.196)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(325.358 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(327.682)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(328.843 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(331.168)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(332.33 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(334.654)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(335.816 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(338.14)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(339.301 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(341.626)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(342.788 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(345.112)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(346.274 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 4.667)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 8.212)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 11.756)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 15.301)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 18.845)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 22.389)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 25.934)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 29.478)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 33.022)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(1.162 1.162)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(348.598 1.162)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(144.473 100.345)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(143.308 99.183)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(140.978 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(139.812 99.183)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(137.482 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(136.317 99.183)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(133.987 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(132.821 99.183)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(130.491 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(129.326 99.183)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(126.996 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(125.83 99.183)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(123.5 100.345)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(122.335 99.183)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(120.004 100.345)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(118.839 99.183)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(116.509 100.345)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(115.344 99.183)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(113.013 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(111.848 99.183)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(109.518 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(108.353 99.183)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(106.022 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(104.857 99.183)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(102.527 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(101.361 99.183)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(99.031 100.345)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(97.866 99.183)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(95.536 100.345)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(94.371 99.183)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(92.04 100.345)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(90.875 99.183)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(88.544 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(87.379 99.183)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(85.049 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(83.884 99.183)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(81.554 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(80.388 99.183)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(78.058 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(76.893 99.183)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(74.562 100.345)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(73.397 99.183)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(71.067 100.345)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(69.901 99.183)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(67.571 100.345)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(66.405 99.183)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(64.076 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(62.911 99.183)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(60.58 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(59.415 99.183)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(57.085 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(55.919 99.183)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(53.589 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(52.424 99.183)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(50.094 100.345)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(48.929 99.183)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(46.599 100.345)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(45.433 99.183)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(43.103 100.345)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(41.937 99.183)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(39.606 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(38.442 99.183)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(36.11 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(34.946 99.183)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(32.616 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(31.451 99.183)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(29.121 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(27.955 99.183)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(25.625 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(24.459 99.183)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(22.129 100.345)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(20.964 99.183)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(18.634 100.345)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(17.469 99.183)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(15.138 100.345)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(13.974 99.183)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(11.644 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(10.478 99.183)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(8.147 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(6.981 99.183)" fill="#fbfafa" />
                <rect width="2.33" height="2.324" transform="translate(4.651 100.345)" fill="#fbfafa" />
                <rect width="2.331" height="2.324" transform="translate(3.485 99.183)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 96.82)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 95.638)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 93.275)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 92.094)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 89.731)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 88.55)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 86.187)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 85.005)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 82.642)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 81.461)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 79.098)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 77.917)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 75.554)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 74.372)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 72.009)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 70.828)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 68.465)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 67.283)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 64.921)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 63.739)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 61.377)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 60.195)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 57.832)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 56.651)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 54.288)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 53.106)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 50.743)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 49.562)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 47.199)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 46.018)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 43.655)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 42.473)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 40.111)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 38.929)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(0 36.566)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(1.162 35.385)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 3.486)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 7.03)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 10.575)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 14.119)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 17.663)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 21.207)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 24.752)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 28.296)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 31.841)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 35.385)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 36.566)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 38.929)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 40.111)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 42.474)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 43.655)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 46.018)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 47.199)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 49.562)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 50.744)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 53.106)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 54.288)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 56.651)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 57.832)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 60.195)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 61.376)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 63.74)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 64.921)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 67.284)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 68.465)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 70.828)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 72.01)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 74.372)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 75.554)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 77.917)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 79.098)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 81.461)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 82.642)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 85.005)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 86.187)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 88.55)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 89.731)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 92.094)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 93.275)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(349.76 95.638)" fill="#fbfafa" />
                <rect width="2.324" height="2.363" transform="translate(348.598 96.82)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(346.248 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(345.073 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(342.723 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(341.548 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(339.198 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(338.023 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(335.674 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(334.499 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(332.149 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(330.974 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(328.624 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(327.45 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(325.1 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(323.925 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(321.575 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(320.4 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(318.05 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(316.875 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(314.526 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(313.351 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(311.001 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(309.826 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(307.477 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(306.302 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(303.952 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(302.777 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(300.427 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(299.252 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(296.902 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(295.728 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(293.378 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(292.203 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(289.853 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(288.678 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(286.329 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(285.154 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(282.804 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(281.629 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(279.279 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(278.104 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(275.754 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(274.58 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(272.229 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(271.055 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(268.705 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(267.53 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(265.181 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(264.006 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(261.656 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(260.481 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(258.131 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(256.957 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(254.606 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(253.432 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(251.082 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(249.907 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(247.557 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(246.382 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(244.033 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(242.858 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(240.508 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(239.333 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(236.983 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(235.809 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(233.458 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(232.284 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(229.934 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(228.759 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(226.409 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(225.234 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(222.885 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(221.71 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(219.36 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(218.185 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(215.835 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(214.661 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(212.311 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(211.136 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(208.786 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(207.611 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(205.261 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(204.086 99.183)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(201.737 100.345)" fill="#fbfafa" />
                <rect width="2.35" height="2.324" transform="translate(200.562 99.183)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(0 100.345)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(1.162 99.183)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(349.76)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(349.76 100.345)" fill="#fbfafa" />
                <rect width="2.324" height="2.324" transform="translate(348.598 99.183)" fill="#fbfafa" />
            </g>

            <g transform="translate(173 102)" class="blinkNext" v-if="!lock">
                <path
                    d="M191.143,102.6H174v2.857h2.858v2.858h2.856v2.856h2.858v2.858h2.857v-2.858h2.858v-2.856h2.856v-2.858H194V102.6Z"
                    transform="translate(-174 -102.602)" fill="#fff" />
            </g>

            <g v-if="name != null && name != undefined && name != ''">
                <rect :width="bgSizes.w" :height="bgSizes.h" :transform="bgSizes.t" :fill="bgcolor" />
                <text x="70" y="10.5" text-anchor="middle" dominant-baseline="central" class="nameStyle">
                    {{ name }}
                </text>
                <g :transform="bgSizes.s" v-if="soundicon">
                    <rect width="24" height="17" :fill="bgcolor" />
                    <!-- 
                    <path
                        d="M383.553,0l-1.159,1.159a10.606,10.606,0,0,1,0,13.831l1.159,1.159a12.239,12.239,0,0,0,0-16.15"
                        transform="translate(-363.826)" fill="#fff" />
                    <path
                        d="M335.731,47.749l-1.163,1.163a7.342,7.342,0,0,1,0,9.186l1.163,1.163a8.981,8.981,0,0,0,0-11.513"
                        transform="translate(-318.322 -45.43)" fill="#fff" />
                    <path d="M286.394,96.957a4.074,4.074,0,0,1,0,4.508l1.17,1.17a5.684,5.684,0,0,0,0-6.847Z"
                        transform="translate(-272.487 -91.136)" fill="#fff" />
                    <path
                        d="M10.6,20.636a9.005,9.005,0,0,0-3.933,2.355,6.283,6.283,0,0,1-4.315,1.679H1.081A1.032,1.032,0,0,0,0,25.638v4.037a1.032,1.032,0,0,0,1.081.969H2.356a6.283,6.283,0,0,1,4.315,1.679A9.006,9.006,0,0,0,10.6,34.677c.6.144,1.2.02,1.2-.541V21.177c0-.56-.594-.684-1.2-.54"
                        transform="translate(0 -19.582)" fill="#fff" /> -->
                    <g transform="translate(0 3)">
                        <path d="M383.316,0l-.922.922a8.436,8.436,0,0,1,0,11l.922.922a9.734,9.734,0,0,0,0-12.845"
                            transform="translate(-367.626)" fill="#fff" />
                        <path d="M335.493,47.749l-.925.925a5.84,5.84,0,0,1,0,7.306l.925.925a7.143,7.143,0,0,0,0-9.157"
                            transform="translate(-321.647 -45.905)" fill="#fff" />
                        <path d="M286.394,96.717a3.24,3.24,0,0,1,0,3.585l.931.93a4.521,4.521,0,0,0,0-5.446Z"
                            transform="translate(-275.333 -92.088)" fill="#fff" />
                        <path
                            d="M8.434,20.625A7.162,7.162,0,0,0,5.306,22.5a5,5,0,0,1-3.432,1.335H.86A.821.821,0,0,0,0,24.6v3.211a.821.821,0,0,0,.86.771H1.873A5,5,0,0,1,5.306,29.92a7.163,7.163,0,0,0,3.129,1.873c.481.114.953.016.953-.43V21.055c0-.446-.473-.544-.953-.43"
                            transform="translate(0 -19.786)" fill="#fff" />
                    </g>

                </g>
            </g>

            <text x="183" y="60" text-anchor="middle" dominant-baseline="central" :style="fontstyle" class="textStyle">
                {{ textBr0 }}
            </text>
            <text x="183" y="48" text-anchor="middle" dominant-baseline="central" :style="fontstyle" class="textStyle">
                {{ textBr1 }}
            </text>
            <text x="183" y="74" text-anchor="middle" dominant-baseline="central" :style="fontstyle" class="textStyle">
                {{ textBr2 }}
            </text>

        </svg>
    </div>
</template>

<style scoped>
.nameStyle {
    font-family: 'M PLUS Rounded 1c',
        sans-serif;
    font-size: 14px;
    fill: #ffffff;
}

.textStyle {
    font-family: 'M PLUS Rounded 1c',
        sans-serif;
    fill: #ffffff;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.blinkNext {
    color: white;
    transform-origin: center center;
    animation-name: blinkAnime;
    animation-duration: 1.5s;
    animation-delay: 0;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}

@keyframes blinkAnime {
    0% {
        opacity: 1;
    }

    49% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}
</style>
<script>

export default {
    data: () => ({
        textBr1: "",
        textBr2: "",
        textBr0: "",
        bgSizes: { w: 63, h: 22, t: 'translate(39.2 0)', s: 'translate(102 1)' },
    }),
    props: {
        name: {
            type: String,
            default: ""
        },
        text: {
            type: String,
            default: ""
        },
        lock: {
            type: Boolean,
            default: false
        },
        soundicon: {
            type: Boolean,
            default: false
        },
        bgcolor: {
            type: String,
            default: "#000000"
        },
        fontstyle: {
            type: String,
            default: "font-size: 16px;"
        },
    },
    mounted: function () {
        this.updateText(this.text);
        if (this.name && this.name.length > 4) {
            this.bgSizes = { w: 83, h: 22, t: 'translate(29.2 0)', s: 'translate(112 1)' };
        }
    },
    destroyed: function () {
    },
    methods: {
        updateText(viewText) {
            const texts = viewText.split("\n");
            if (texts.length == 2) {
                this.textBr0 = "";
                this.textBr1 = texts[0];
                this.textBr2 = texts[1];
            } else {
                this.textBr0 = viewText;
                this.textBr1 = "";
                this.textBr2 = "";
            }
        }
    },
    computed: {
    },
    watch: {
        text: function (newVal) {
            this.updateText(newVal);
        },
        name: function (newVal) {
            if (newVal && newVal.length > 4) {
                this.bgSizes = { w: 83, h: 22, t: 'translate(29.2 0)', s: 'translate(112 1)' };
            }
        }
    }
}
</script>
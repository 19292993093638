<template>
    <v-container class="baseStyle">
        <v-row>
            <v-col cols="12" class="ma-0 pa-0">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
                    height="100%" viewBox="0 0 427 1000">
                    <g transform="translate(0 0)">
                        <image width="427" height="921" xlink:href="/images/rally4/bg.jpg"></image>
                    </g>
                    <g transform="translate(38 209)">
                        <image width="345" height="1199" xlink:href="/images/rally4/diabro.png"></image>
                    </g>
                </svg>
            </v-col>
        </v-row>
        <div class="msgArea">
            <div @click="goNext">
                <MessageWindow :name="'ディアブロ'" :text="'さすがはリムル様のご客人。\n見事です。'" :bgcolor="'#000000'">
                </MessageWindow>
            </div>
        </div>
    </v-container>
</template>

<style scoped>
.baseStyle {
    position: relative;
    padding: 0 !important;
    width: 100%;
    max-width: 480px;
}

.msgArea {
    position: fixed;
    width: 100%;
    padding: 0 3vw;
    bottom: 30px;
    left: 0;
    text-align: center;
    z-index: 1;
}

.msgArea>div {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
}
</style>
<script>
import MessageWindow from "./components/MessageWindow";

export default {
    components: {
        MessageWindow,
    },
    data: () => ({

    }),
    mounted: async function () {
        try {
            document.getElementsByTagName("html")[0].classList.add("noScroll")
        } catch (error) { }

        let appDom = document.getElementById("app");
        appDom.classList.add("rally4Bg");

    },
    destroyed: function () {
        try {
            document.getElementsByTagName("html")[0].classList.remove("noScroll")
        } catch (error) { }

        let appDom = document.getElementById("app");
        appDom.classList.remove("rally4Bg");
        appDom.classList.remove("tegataBg");
    },
    methods: {
        goNext() {
            this.$router.replace({ name: 'rallymap' });
        }
    },
    computed: {
    },
    watch: {

    }
};
</script>

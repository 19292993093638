<template>
    <v-container class="baseStyle">
        <v-row>
            <v-col cols="12" class="ma-0 pa-0">
                <tegata v-if="nowStory == 1" :name="$store.getters.userName" :animation="true" :stamp="4" :ura="false"
                    :receive="false">
                </tegata>

                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
                    height="100%" viewBox="0 0 427 1000" v-else>
                    <g transform="translate(0 0)">
                        <image width="427" height="921" xlink:href="/images/rally4/bg.jpg"></image>
                    </g>
                    <g transform="translate(38 209)">
                        <image width="345" height="1199" xlink:href="/images/rally4/diabro.png"></image>
                    </g>
                </svg>
            </v-col>
        </v-row>
        <div class="msgArea">
            <div @click="storyNext">
                <MessageWindow :name="msgName" :text="msgText" :lock="isLockStory" :bgcolor="msgBgColor">
                </MessageWindow>
            </div>
        </div>

    </v-container>
</template>

<style scoped>
.baseStyle {
    position: relative;
    padding: 0 !important;
    width: 100%;
    max-width: 480px;
}

.msgArea {
    position: fixed;
    width: 100%;
    padding: 0 3vw;
    bottom: 30px;
    left: 0;
    text-align: center;
    z-index: 1;
}

.msgArea>div {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
}
</style>
<script>
import MessageWindow from "./components/MessageWindow";
import Tegata from "./components/Tegata";

export default {
    components: {
        MessageWindow,
        Tegata
    },
    data: () => ({
        nowStory: 0,
        msgName: '',
        msgText: '',
        msgBgColor: '#000000',
        lockStoryTimer: undefined,
        isLockStory: false,
    }),
    mounted: async function () {
        try {
            document.getElementsByTagName("html")[0].classList.add("noScroll")
        } catch (error) { }

        let appDom = document.getElementById("app");
        appDom.classList.add("rally4Bg");

        this.nowStory = this.$store.getters.viewStoryNum;
        if (this.nowStory > 0) {
            this.nowStory = 0;
        }
        this.storyEvent();
    },
    destroyed: function () {
        try {
            document.getElementsByTagName("html")[0].classList.remove("noScroll")
        } catch (error) { }

        let appDom = document.getElementById("app");
        appDom.classList.remove("rally4Bg");
        appDom.classList.remove("tegataBg");
    },
    methods: {
        storyNext(isForce) {
            if (isForce != true && this.isLockStory == true) {
                console.log("lock")
                return;
            }
            this.isLockStory = true;
            this.nowStory++;
            if (this.nowStory < 1) {
                this.$store.commit("setStoryNum", this.nowStory);
            }
            this.storyEvent();
        },
        storyEvent() {
            let nextTiming = 500;
            // console.log(this.nowStory)
            clearTimeout(this.autoStoryTimer);

            let appDom = document.getElementById("app");

            switch (this.nowStory) {
                case 0:
                    this.msgName = "ディアブロ";
                    this.msgText = 'では、無事に私の仕事は\n終えましたのでこちらを。それでは。';
                    break;
                case 1:
                    this.msgBgColor = "#960000";
                    this.msgName = "";
                    this.msgText = "湯めぐり手形に\n４つ目のスタンプが押された！";
                    appDom.classList.remove("rally4Bg");
                    appDom.classList.add("tegataBg");
                    this.$store.commit("clearRally4");

                    break;
                case 2:
                    this.goNext();
                default:
                    this.msgBgColor = "#000000";
                    this.msgName = "";
                    this.msgText = "";
                    break;
            }
            this.lockStoryTimer = setTimeout(() => {
                this.isLockStory = false;
            }, nextTiming)
        },
        goNext() {
            this.$router.replace({ name: 'rallymap' });
        }
    },
    computed: {
    },
    watch: {

    }
};
</script>

<template>
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%"
            viewBox="0 0 427 730" @click="eventClick">
            <g transform="translate(0 0)">
                <image width="427" height="730" xlink:href="/images/tegata/bg.jpg"></image>
            </g>
            <g transform="translate(23 211)">
                <g :class="receive ? 'tegataStart_receive' : ''">
                    <g transform="translate(0 0)"
                        :class="viewMode == 0 ? 'tegataDefOmote' : (viewMode == 1 ? 'tegataGoUra_Omote' : 'tegataGoOmote_Omote')">
                        <image width="429" height="276" xlink:href="/images/tegata/tegata_omote.png">
                        </image>

                        <g transform="translate(33 148)" v-if="stamp >= 1">
                            <image width="52" height="37" xlink:href="/images/tegata/stamp1.png"
                                :class="(stamp == 1 && animation) ? 'stampIn' : ''"></image>
                            <g transform="translate(0 3)" v-if="stamp == 1 && animation">
                                <g class="hitCir">
                                    <circle cx="22" cy="16" r="15" />
                                    <circle cx="22" cy="16" r="25" />
                                </g>
                            </g>
                        </g>
                        <g transform="translate(93 185)" v-if="stamp >= 2">
                            <image width="43" height="32" xlink:href="/images/tegata/stamp2.png"
                                :class="(stamp == 2 && animation) ? 'stampIn' : ''"></image>
                            <g transform="translate(0 3)" v-if="stamp == 2 && animation">
                                <g class="hitCir">
                                    <circle cx="22" cy="16" r="15" />
                                    <circle cx="22" cy="16" r="25" />
                                </g>
                            </g>
                        </g>
                        <g transform="translate(145 156)" v-if="stamp >= 3">
                            <image width="44" height="37" xlink:href="/images/tegata/stamp3.png"
                                :class="(stamp == 3 && animation) ? 'stampIn' : ''"></image>
                            <g transform="translate(0 3)" v-if="stamp == 3 && animation">
                                <g class="hitCir">
                                    <circle cx="22" cy="16" r="15" />
                                    <circle cx="22" cy="16" r="25" />
                                </g>
                            </g>
                        </g>
                        <g transform="translate(210 184)" v-if="stamp >= 4">
                            <image width="39" height="34" xlink:href="/images/tegata/stamp4.png"
                                :class="(stamp == 4 && animation) ? 'stampIn' : ''"></image>
                            <g transform="translate(0 3)" v-if="stamp == 4 && animation">
                                <g class="hitCir">
                                    <circle cx="22" cy="16" r="15" />
                                    <circle cx="22" cy="16" r="25" />
                                </g>
                            </g>
                        </g>
                        <g transform="translate(251 156)" v-if="stamp >= 5">
                            <image width="49" height="37" xlink:href="/images/tegata/stamp5.png"
                                :class="(stamp == 5 && animation) ? 'stampIn' : ''"></image>
                            <g transform="translate(0 3)" v-if="stamp == 5 && animation">
                                <g class="hitCir">
                                    <circle cx="22" cy="16" r="15" />
                                    <circle cx="22" cy="16" r="25" />
                                </g>
                            </g>
                        </g>
                        <g transform="translate(172 242)" v-if="name != ''">
                            <g :class="(stamp == 0 && animation) ? 'nameIn' : ''">
                                <text x="0" y="0" text-anchor="middle" font-weight="bold" font-family="Kaisei Opti"
                                    font-size="14" ref="username">
                                    {{ viewName }}
                                </text>
                            </g>

                        </g>
                    </g>
                </g>
            </g>
            <g transform="translate(-30 211)">
                <g transform="translate(0 0)"
                    :class="viewMode == 0 ? 'tegataDefUra' : (viewMode == 1 ? 'tegataGoUra_Ura' : 'tegataGoOmote_Ura')">
                    <image width="421" height="271" xlink:href="/images/tegata/tegata_ura.png">
                    </image>
                </g>
            </g>


            <g transform="translate(-10 201)"
                :class="viewMode == 0 ? 'tegataDefFlash' : (viewMode == 1 ? 'tegataGoUra_Flash' : 'tegataGoOmote_Flash')">
                <image width="447" height="297" xlink:href="/images/tegata/base_flash.png">
                </image>
            </g>

            <!-- <g transform="translate(-10 201)" v-if="receive" class="tegataStart_flash">
                <image width="447" height="297" xlink:href="/images/tegata/base_flash.png">
                </image>
            </g> -->
        </svg>
    </div>
</template>


<style scoped>
.tegataDefOmote {
    opacity: 1;
}

.tegataDefUra {
    opacity: 0;
}

.tegataDefFlash {
    opacity: 0;
}

.tegataGoUra_Omote {
    opacity: 1;
    transform-origin: center center;
    animation-name: tegataGoUraAnimeOmote;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.tegataGoUra_Flash {
    opacity: 0;
    transform-origin: center center;
    animation-name: tegataGoUraAnimeFlash;
    animation-duration: 1.2s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
}

.tegataGoUra_Ura {
    opacity: 0;
    transform-origin: center center;
    animation-name: tegataGoUraAnimeUra;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
}

.tegataGoOmote_Omote {
    opacity: 0;
    transform-origin: center center;
    animation-name: tegataGoOmoteAnimeOmote;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
}

.tegataGoOmote_Flash {
    opacity: 0;
    transform-origin: center center;
    animation-name: tegataGoOmoteAnimeFlash;
    animation-duration: 1.2s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
}

.tegataGoOmote_Ura {
    opacity: 1;
    transform-origin: center center;
    animation-name: tegataGoOmoteAnimeUra;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.tegataStart_flash {
    opacity: 0;
    transform-origin: center center;
    animation-name: targetStartFlash;
    animation-duration: 3.2s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
}

.tegataStart_receive {
    opacity: 0;
    transform-origin: 215px 138px;
    animation-name: targetStartIn;
    animation-duration: 1.2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
}

.stampIn {
    opacity: 0;
    animation-name: stampAnime;
    animation-delay: 0.5s;
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
    transform-origin: 0 0;
}

.nameIn {
    opacity: 0;
    animation-name: nameAnime;
    animation-delay: 0;
    animation-duration: 3s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
    transform-origin: 0 0;
}


.hitCir {
    fill: transparent;
    stroke: #960000;
    stroke-width: 5;
    animation: hitAnime 1.8s 1 0.8s, hitAnime2 0.6s 1 0.6s;
    transform-origin: 22px 16px;
    opacity: 0;
}

@keyframes hitAnime {
    0% {
        opacity: 0;
        stroke-width: 1;
        transform: scale(0);
    }

    10% {
        opacity: 0.8;
        stroke-width: 5;
    }

    100% {
        opacity: 0;
        stroke-width: 1;
        transform: scale(2);
    }
}

@keyframes hitAnime2 {
    0% {
        opacity: 0;
        stroke-width: 0.5;
        transform: scale(0);
    }

    40% {
        opacity: 0.2;
        stroke-width: 2;
    }

    100% {
        opacity: 0;
        stroke-width: 1;
        transform: scale(2);
    }
}

@keyframes tegataGoUraAnimeOmote {
    0% {
        opacity: 1;
        transform: rotateY(0deg);
    }

    70% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: rotateY(80deg);
    }
}

@keyframes tegataGoUraAnimeFlash {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes tegataGoUraAnimeUra {
    0% {
        opacity: 0;
        transform: rotateY(80deg);
    }

    30% {
        opacity: 1;
    }

    100% {
        opacity: 1;
        transform: rotateY(0deg);
    }
}

@keyframes tegataGoOmoteAnimeOmote {
    0% {
        opacity: 0;
        transform: rotateY(80deg);
    }

    30% {
        opacity: 1;
    }

    100% {
        opacity: 1;
        transform: rotateY(0deg);
    }
}

@keyframes tegataGoOmoteAnimeFlash {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes tegataGoOmoteAnimeUra {
    0% {
        opacity: 1;
        transform: rotateY(0deg);
    }

    70% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: rotateY(80deg);
    }
}

@keyframes stampAnime {
    0% {
        opacity: 0;
        transform: rotate(10deg) scale(3);
        filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 1));
    }

    30% {
        opacity: 0.3;
        transform: rotate(0deg) scale(1.05);
        filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 1));
    }

    100% {
        opacity: 1.0;
        transform: scale(1);
    }
}

@keyframes nameAnime {

    0% {
        opacity: 0;
        transform: rotate(10deg) scale(1.7);
    }

    10% {
        opacity: 0.8;
    }

    90% {
        opacity: 0.8;
        transform: rotate(-3deg) scale(2.1);
    }

    95% {
        opacity: 1;
        transform: rotate(0deg) scale(0.95);
    }

    100% {
        opacity: 1.0;
        transform: rotate(0deg) scale(1);
    }
}


@keyframes targetStartFlash {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes targetStartIn {
    0% {
        opacity: 0;
        transform: scale(0) rotate(720deg);
    }

    80% {
        opacity: 0.8;
        transform: scale(0.8) rotate(0);
    }

    90% {
        opacity: 0.8;
        transform: scale(0.7) rotate(0);
    }

    100% {
        opacity: 1;
        transform: scale(1) rotate(0);
    }
}
</style>
<script>

export default {
    data: () => ({
        viewMode: 0,
        viewName: "",
        createName: ""
    }),
    props: {
        name: {
            type: String,
            default: ""
        },
        stamp: {
            type: Number,
            default: 0
        },
        animation: {
            type: Boolean,
            default: false
        },
        ura: {
            type: Boolean,
            default: true
        },
        receive: {
            type: Boolean,
            default: false
        },
    },
    created: function () {
        if (this.animation && this.stamp == 0) {
            this.createName = this.name + "殿";
            this.typeName(1);
        } else {
            this.viewName = this.name + "殿";
        }
    },
    destroyed: function () {
    },
    methods: {
        eventClick() {
            if (this.ura == true) {
                if (this.viewMode == 1) {
                    this.viewMode = 2;
                } else {
                    this.viewMode = 1;
                }
            }
        },
        typeName(num) {
            this.viewName = this.createName.substring(0, num);
            if (this.createName.length > num) {
                setTimeout(() => {
                    this.typeName(num + 1);
                }, 160);
            }
        }
    },
    computed: {
    },
    watch: {
        name: function (newVal) {
            if (this.animation && this.stamp == 0) {
                this.createName = this.name + "殿";
                this.typeName(1);
            } else {
                this.viewName = this.name + "殿";
            }
            if (this.viewMode == 1) {
                this.viewMode = 2;
            }
        }
    }
}
</script>
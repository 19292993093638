

<template>
    <v-dialog v-model="isOpen" max-width="400">
        <v-card>
            <v-card-text class="dialogText">
                <v-row class="pa-0">
                    <v-col cols="12" class="text-center" v-show="!isArchives">
                        <img ref="capImage" width="100%" src="/images/present/frame.png"
                            style="background-color:#aaa;border:2px solid white;" />
                        <div class="text-right infoArea mt-3 mb-1">
                            <v-icon size="2em" class="ma-0 pa-0" @click="switchCamera">mdi-camera-flip-outline</v-icon>
                        </div>
                    </v-col>
                    <v-col cols="12" class="text-center" v-show="isArchives">
                        <img ref="shotImage" width="100%" style="background-color:#aaa;border:2px solid white;" />
                        <div v-if="isArchives && sendShotStatus == 9">
                            <v-alert type="error text-left">
                                <span>LINEの権限やサーバの問題で送れませんでした。写真を長押しすることで保存もできます。</span>
                            </v-alert>
                        </div>
                        <div v-if="isLineApp" class="mt-2">
                            <v-btn width="100%" :disabled="sendShotStatus != 0 && sendShotStatus != 9"
                                class="btn_orange sendline" @click="saveShot">
                                <div v-if="sendShotStatus == 0">撮影した写真をLINEに送る</div>
                                <div v-else-if="sendShotStatus == 1" class="loader">送信中</div>
                                <div v-else-if="sendShotStatus == 2">トークに送りました</div>
                                <div v-else-if="sendShotStatus == 9">再度写真をLINEに送る</div>
                            </v-btn>
                        </div>
                        <div class="text-center saveInfoText infoArea mt-4" v-else>
                            撮影した写真は長押しで保存してください
                        </div>
                    </v-col>
                </v-row>

                <v-row class="pa-0 mt-0">
                    <v-col cols="6" v-if="!isArchives">
                        <v-btn class="btn_orange" width="100%" :disabled="!isActiveCamera"
                            @click="execShot">撮影する</v-btn>
                    </v-col>
                    <v-col cols="6" class="text-center" v-else>
                        <v-btn width="100%" class="btn_purple" @click="isArchives = false">撮り直し</v-btn>
                    </v-col>

                    <v-col cols="6">
                        <v-btn class="btn_purple" width="100%" @click="isOpen = false">やめる</v-btn>
                    </v-col>
                </v-row>

            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<style scoped src='@/assets/style/windowAkawaku.css'>

</style>
<style scoped>
.switchCamera {
    height: min(13vw, 48px);
}

.saveInfoText {
    font-size: 0.9em;
}

.infoArea {
    min-height: 32px;
}

.sendline {
    letter-spacing: -0.03em
}
</style>
<script>
import axios from "axios";

export default {
    data: () => ({
        isOpen: true,
        shotCamera: null,
        isArchives: false,
        arcivesBase64: null,
        sendShotStatus: 0,
        isActiveCamera: false,
    }),
    props: {
    },
    created: function () {
        this.isArchives = false;
        this.isActiveCamera = false;
    },
    mounted: function () {
        this.viewShot();
    },
    destroyed: function () {
        this.$stamp.stopCamera();
        this.arcivesBase64 = null;
    },
    methods: {
        async viewShot(count, func) {
            if (!count) count = 1;
            if (this.$refs.capImage) {
                try {
                    this.isActiveCamera = false;
                    await this.$stamp.shotCamera(this.$refs.capImage, "/images/present/frame.png", () => {
                        this.isActiveCamera = true;
                    });
                } catch (error) {
                    console.error(error);
                    this.$router.replace({ name: 'error', params: { 'errormessage': "カメラなどのデバイスの利用ができません。" } });
                }
            } else if (count < 5) {
                setTimeout(() => {
                    this.viewShot(count + 1, func)
                }, 300);
            }
        },
        switchCamera() {
            this.isActiveCamera = false;
            this.$stamp.switchCamera(() => {
                this.isActiveCamera = true;
            });
        },
        execShot() {
            try {
                this.arcivesBase64 = this.$stamp.getShotCameraData();
                if (!this.arcivesBase64) throw new Error("not image");
                this.sendShotStatus = 0;
                this.isArchives = true;
                this.viewArchiveShot();
                gtag('event', 'photoframe', { 'event_category': 'shot', 'event_label': this.nowMission });
            } catch (error) {
                console.error(error);
            }
        },
        viewArchiveShot(count) {
            if (!count) count = 1;
            if (this.$refs.shotImage) {
                this.$refs.shotImage.src = this.arcivesBase64;
            } else if (count < 5) {
                setTimeout(() => {
                    this.viewArchiveShot(count + 1)
                }, 300);
            }
        },
        async saveShot() {
            this.sendShotStatus = 1;
            try {
                gtag('event', 'photoframe', { 'event_category': 'save', 'event_label': this.nowMission });
                // console.log("PHOTO SEND START");
                let res = await axios.post(
                    process.env.VUE_APP_SERVER_SAVE_PATH + 'photo',
                    {
                        'service': process.env.VUE_APP_SERVER_SERVICE_ID,
                        'lineUserHashKey': this.$store.getters.lineUserHashKey,
                        'localUserId': this.$store.getters.localUserId,
                        'image': this.arcivesBase64
                    });

                console.log("PHOTO SEND SERVER", res);
                if (res.data.error) {
                    throw new Error('not save image');
                }
                await liff.sendMessages([
                    {
                        type: 'text',
                        text: '撮影した写真はこれだよ！'
                    },
                    {
                        "type": "image",
                        "originalContentUrl": res.data.path,
                        "previewImageUrl": res.data.path
                    },
                ])
                // console.log("PHOTO LINE SEND OK");
                this.sendShotStatus = 2;
            } catch (error) {
                console.error("PHOTO ERROR", error);
                this.sendShotStatus = 9;
            }
        },

    },
    computed: {
        isLineApp() {
            return typeof (this.$store.getters.lineUserHashKey) == "string"
        }
    },
    watch: {
        isOpen(newVal) {
            if (!newVal) {
                this.$emit('close');
                this.$stamp.stopCamera();
            }
        }
    }
}
</script>
<template>
    <v-container class="baseStyle">
        <v-row>
            <v-col cols="12" class="ma-0 pa-0">

                <tegata v-if="nowStory == 0" :name="$store.getters.userName" :animation="true" :stamp="5" :ura="false"
                    :receive="false">
                </tegata>

                <svg v-else-if="nowStory == 2" xmlns="http://www.w3.org/2000/svg" version="1.1"
                    xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 427 730">
                    <g transform="translate(0 0)">
                        <image width="427" height="730" xlink:href="/images/tegata/bg.jpg"></image>
                    </g>
                    <g transform="translate(23 211)">
                        <g class="tegata2meister2">
                            <image width="429" height="276" xlink:href="/images/tegata/tegata_omote.png">
                            </image>
                            <image width="52" height="37" xlink:href="/images/tegata/stamp1.png"
                                transform="translate(33 148)"></image>
                            <image width="43" height="32" xlink:href="/images/tegata/stamp2.png"
                                transform="translate(93 185)"></image>
                            <image width="44" height="37" xlink:href="/images/tegata/stamp3.png"
                                transform="translate(145 156)"></image>
                            <image width="39" height="34" xlink:href="/images/tegata/stamp4.png"
                                transform="translate(210 184)"></image>
                            <image width="49" height="37" xlink:href="/images/tegata/stamp5.png"
                                transform="translate(251 156)"></image>
                            <text x="172" y="242" text-anchor="middle" font-weight="bold" font-family="Kaisei Opti"
                                font-size="14">
                                {{ $store.getters.userName }}
                            </text>

                        </g>

                    </g>
                    <g transform="translate(20 85)">
                        <image width="396" height="472" xlink:href="/images/rally5/meister.png" class="tegata2meister">
                        </image>
                    </g>
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
                    height="100%" viewBox="0 0 428 830" v-else-if="nowStory == -1 || nowStory >= 3 && nowStory <= 19">
                    <g transform="translate(0 0)">
                        <image width="428" height="830" xlink:href="/images/rally5/bg.jpg"></image>
                    </g>
                    <g transform="translate(20 240)" v-if="nowStory == 4">
                        <image width="372" height="280" xlink:href="/images/rally5/koma1.png"></image>
                    </g>
                    <g transform="translate(48 203)" v-if="nowStory == 8 || nowStory == 9">
                        <image width="218" height="163" xlink:href="/images/rally5/koma2.png"></image>
                    </g>
                    <g transform="translate(0 285)" v-if="(nowStory >= 13 && nowStory <= 15) || nowStory == 19">
                        <image width="389" height="224" xlink:href="/images/rally5/koma3.jpg" class="koma2"></image>
                    </g>
                </svg>

                <svg v-else-if="nowStory == 20 || nowStory == 21" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 427 994"
                    @click="storyNext">
                    <g transform="translate(0 0)">
                        <image width="427" height="994" xlink:href="/images/rally5/bgend.jpg"></image>
                    </g>

                    <g v-if="nowStory == 20" transform="translate(0 0)">
                        <image width="427" height="730" xlink:href="/images/rally5/clear_kira.png"></image>
                    </g>

                    <g v-if="nowStory == 20" transform="translate(20 195)">
                        <image width="396" height="402" xlink:href="/images/rally5/meister.png"></image>
                    </g>
                    <g v-if="nowStory == 20" transform="translate(320 675)" class="fadeIn">
                        <image width="70" height="22" xlink:href="/images/rally5/clear_end.png"></image>
                    </g>
                    <g v-if="nowStory == 21" transform="translate(48 105)">
                        <g transform="translate(0 0)">
                            <rect x="0" y="0" width="342" height="496" stroke="#ffffff" stroke-width="8" />
                            <image width="342" height="496" xlink:href="/images/present/frame_sample.jpg"></image>
                            <rect width="342" height="496" rx="10" fill="url(#kougen)" />
                        </g>
                    </g>

                    <linearGradient id="kougen" x1="0%" y1="0%" x2="100%" y2="40%">
                        <stop offset="0.4" style="stop-color:rgba(255,255,255,0)" />
                        <stop offset="0.5" style="stop-color:rgba(255,255,255,0.6)" />
                        <stop offset="0.51" style="stop-color:rgba(255,255,255,0)" />
                        <animate attributeName="x1" dur="7s" from="-300%" to="300%" repeatCount="indefinite" />
                        <animate attributeName="x2" dur="7s" from="-200%" to="2400%" repeatCount="indefinite" />
                        <animate attributeName="y1" dur="7s" from="-300%" to="300%" repeatCount="indefinite" />
                        <animate attributeName="y2" dur="7s" from="-200%" to="2400%" repeatCount="indefinite" />
                    </linearGradient>
                </svg>

                <div class="pa-5 videoArea" v-else-if="nowStory == 1">
                    <video src="/images/rally5/ending.mp4" poster="/images/rally5/poster.jpg" width="100%"
                        ref="endingMovie" controls playsinline disablePictureInPicture
                        controlsList="nodownload noremoteplayback" oncontextmenu="return false;"></video>
                    <div class="text-center mt-15 skipArea" @click="goSkip" ref="movieSkip">
                        <div class="skipText">＞＞ スキップ</div>
                    </div>
                </div>
            </v-col>
        </v-row>
        <div class="msgArea"
            v-if="nowStory == -1 || nowStory == 0 || (nowStory >= 2 && nowStory <= 19) || nowStory == 21">
            <div @click="storyNext">
                <MessageWindow :name="msgName" :text="msgText" :lock="isLockStory" :soundicon="msgSoundIcon"
                    :bgcolor="msgBgColor" :fontstyle="msgStyle">
                </MessageWindow>
            </div>
        </div>

        <ConfWindow v-if="dialogConf" @close="eventConfOk" :text="confText"></ConfWindow>


    </v-container>
</template>

<style scoped>
.baseStyle {
    position: relative;
    padding: 0 !important;
    width: 100%;
    max-width: 480px;
}

.videoArea {
    margin-top: 25vh;
}

.msgArea {
    position: fixed;
    width: 100%;
    padding: 0 3vw;
    bottom: 30px;
    left: 0;
    text-align: center;
    z-index: 1;
}

.msgArea>div {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
}


.koma1 {
    transform: translateX(427px);
    animation-name: slideLeftRight;
    animation-duration: 0.6s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
}

.koma2 {
    transform: translateX(-427px);
    animation-name: slideRightLeft;
    animation-duration: 0.6s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
}

video {
    opacity: 0;
}

.endingMovie {
    opacity: 0;
    animation-name: videoAnime;
    animation-duration: 2s;
    animation-delay: 4s;
    animation-fill-mode: forwards;
}

@keyframes videoAnime {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.skipArea {
    position: fixed;
    bottom: 100px;
    left: 0;
    opacity: 0;
    width: 100%;
}

.skipVisible {
    opacity: 1;
}


@keyframes slideLeftRight {
    0% {
        transform: translateX(427px);
    }

    100% {
        transform: translateX(0px);
    }
}

@keyframes slideRightLeft {
    0% {
        transform: translateX(-427px);
    }

    100% {
        transform: translateX(0px);
    }
}

.fadeIn {
    opacity: 0;
    animation-name: fadeIn;
    animation-delay: 0.5s;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.tegata2meister {
    opacity: 0;
    transform-origin: 193px 236px;
    animation-name: targetStartIn;
    animation-duration: 3s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
}

.tegata2meister2 {
    opacity: 1;
    transform-origin: 215px 138px;
    animation-name: targetStartIn2;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
}

@keyframes targetStartIn {
    0% {
        opacity: 0;
        transform: scale(0.3) rotate(720deg);
    }

    80% {
        opacity: 1;
        transform: scale(0.8) rotate(0);
    }

    90% {
        opacity: 1;
        transform: scale(0.7) rotate(0);
    }

    100% {
        opacity: 1;
        transform: scale(1) rotate(0);
    }
}

@keyframes targetStartIn2 {
    0% {
        opacity: 1;
        transform: scale(1) rotate(0);
    }

    100% {
        opacity: 0;
        transform: scale(0.3) rotate(120deg);
    }
}
</style>
<script>
import ConfWindow from "./components/ConfWindow";
import MessageWindow from "./components/MessageWindow";
import Tegata from "./components/Tegata";

export default {
    components: {
        ConfWindow,
        MessageWindow,
        Tegata
    },
    data: () => ({
        dialogConf: false,
        nowStory: 0,
        nextStory: 0,
        msgName: '',
        msgText: '',
        msgStyle: 'font-size: 16px;',
        msgSoundIcon: false,
        msgBgColor: '#000000',
        lockStoryTimer: undefined,
        isLockStory: false,
        voiceSound: new Audio("/images/rally5/voice.mp3"),
        voicePauseTimer: undefined,
        isReplay: false,
        confText: '',
    }),
    mounted: async function () {
        try {
            document.getElementsByTagName("html")[0].classList.add("noScroll")
        } catch (error) { }

        let nowRally = this.$store.getters.nowPlayRally;

        if (nowRally.allclear) {
            this.nowStory = -1;
            this.nextStory = 3;
            this.isReplay = true;
            this.storyEvent();
            // let appDom = document.getElementById("app");
            // appDom.classList.add("rally1Bg");
        } else {
            this.nowStory = this.$store.getters.viewStoryNum;
            if (this.nowStory > 20) {
                this.nowStory = 0;
            }
            if (this.nowStory >= 3 && this.nowStory <= 19) {
                this.nextStory = this.nowStory;
                this.nowStory = -1;
            }
            this.storyEvent();
        }
    },
    destroyed: function () {
        try {
            document.getElementsByTagName("html")[0].classList.remove("noScroll")
        } catch (error) { }

        let appDom = document.getElementById("app");
        appDom.classList.remove("rally1Bg");
        appDom.classList.remove("tegataBg");
        appDom.classList.remove("startBg");
        if (this.voiceSound) {
            this.voiceSound.pause();
        }
    },
    methods: {
        storyNext(isForce) {
            if (isForce != true && this.isLockStory == true) {
                console.log("lock")
                return;
            }
            this.nowStory++;
            if (this.nowStory == 3) {
                this.nowStory = -1;
                this.nextStory = 3;
                this.storyEvent();
            } else {
                if (this.nowStory < 21) {
                    this.$store.commit("setStoryNum", this.nowStory);
                }
                this.storyEvent();
            }
        },
        storyEvent() {
            let nextTiming = 500;
            this.isLockStory = true;
            clearTimeout(this.autoStoryTimer);

            let appDom = document.getElementById("app");

            // console.log("nowStory", this.nowStory)

            switch (this.nowStory) {
                case -1:
                    appDom.classList.remove("startBg");
                    appDom.classList.remove("tegataBg");
                    appDom.classList.add("rally1Bg");
                    this.msgBgColor = "#000000";
                    this.msgName = "";
                    this.msgText = "";
                    this.confText = 'シナリオで音声が流れます。\nミュートを解除して\nお楽しみください。';
                    this.dialogConf = true;
                    break;
                case 0:
                    appDom.classList.add("tegataBg");
                    this.msgBgColor = "#960000";
                    this.msgName = "";
                    this.msgText = "湯めぐり手形に\n５つ目のスタンプが押された！";
                    break;
                case 1:
                    appDom.classList.add("tegataBg");
                    this.msgBgColor = "#000000";
                    this.msgName = "";
                    this.msgText = "";
                    appDom.classList.remove("tegataBg");
                    appDom.classList.add("startBg");
                    this.confText = '映像で音声が流れます。\nミュートを解除して\nお楽しみください。';
                    this.dialogConf = true;
                    break;
                case 2:
                    this.$store.commit("checkRally5Tegata");
                    appDom.classList.remove("startBg");
                    appDom.classList.add("tegataBg");
                    this.msgBgColor = "#960000";
                    this.msgName = "";
                    this.msgText = "湯めぐり手形は\nユネッサン・マイスターに進化した！";
                    break;
                case 3:
                    appDom.classList.remove("startBg");
                    appDom.classList.remove("tegataBg");
                    appDom.classList.add("rally1Bg");
                    this.msgBgColor = "#000000";
                    this.msgSoundIcon = true;
                    this.msgName = "リムル";
                    this.msgText = "おめでとう、それは特別なものにしか\n与えられない証らしいんだ。";
                    this.playVoice(1);
                    break;
                case 4:
                    appDom.classList.add("rally1Bg");
                    this.msgName = "ヴェルドラ";
                    this.msgText = "クワッハッハッハ";
                    this.playVoice(2);
                    break;
                case 5:
                    appDom.classList.add("rally1Bg");
                    this.msgName = "リムル";
                    this.msgText = "俺の作ったユネッサン・テンペストを\n楽しんでもらえてうれしいぞ。";
                    this.playVoice(3);
                    break;
                case 6:
                    appDom.classList.add("rally1Bg");
                    this.msgStyle = 'font-size: 12px;';
                    this.msgText = "（なぁ、大賢者、）";
                    this.playVoice(4);
                    break;
                case 7:
                    appDom.classList.add("rally1Bg");
                    this.msgText = "（ところでユネッサン・マイスターって\nなんだったっけ？）";
                    this.playVoice(5);
                    break;
                case 8:
                    appDom.classList.add("rally1Bg");
                    this.msgName = "大賢者";
                    this.msgText = "（解。温泉のプロフェッショナルの\n一歩と言われており、）";
                    this.playVoice(6);
                    break;
                case 9:
                    appDom.classList.add("rally1Bg");
                    this.msgText = "（ここユネッサン・テンペストのことを\n知り尽くした方の呼称です。）";
                    this.playVoice(7);
                    break;
                case 10:
                    appDom.classList.add("rally1Bg");
                    this.msgStyle = 'font-size: 16px;';
                    this.msgName = "リムル";
                    this.msgText = "なるほど〜。ならばぜひ\nこの温泉アミューズメントパーク";
                    this.playVoice(8);
                    break;
                case 11:
                    appDom.classList.add("rally1Bg");
                    this.msgName = "リムル";
                    this.msgText = "ユネッサン・テンペストの魅力を";
                    this.playVoice(9);
                    break;
                case 12:
                    appDom.classList.add("rally1Bg");
                    this.msgName = "リムル";
                    this.msgText = "我々と共に広めてもらえると嬉しい";
                    this.playVoice(10);
                    break;
                case 13:
                    appDom.classList.add("rally1Bg");
                    this.msgName = "大賢者";
                    this.msgText = "解。";
                    this.playVoice(11);
                    break;
                case 14:
                    appDom.classList.add("rally1Bg");
                    this.msgName = "大賢者";
                    this.msgText = "ユネッサン・マイスターが増えることで\nより魅力が伝わり、";
                    this.playVoice(12);
                    break;
                case 15:
                    appDom.classList.add("rally1Bg");
                    this.msgName = "大賢者";
                    this.msgText = "ユネッサン・テンペストが\n繁栄していくものと考えられます。";
                    this.playVoice(13);
                    break;
                case 16:
                    appDom.classList.add("rally1Bg");
                    this.msgName = "リムル";
                    this.msgText = "今日はバタバタしていて\n申し訳なかった。";
                    this.playVoice(14);
                    break;
                case 17:
                    appDom.classList.add("rally1Bg");
                    this.msgName = "リムル";
                    this.msgText = "ぜひまた来てくれ。";
                    this.playVoice(15);
                    break;
                case 18:
                    appDom.classList.add("rally1Bg");
                    this.msgName = "リムル";
                    this.msgText = "その時にはさらに\n楽しい場所にしておくよ。";
                    this.playVoice(16);
                    break;
                case 19:
                    appDom.classList.add("rally1Bg");
                    this.msgName = "大賢者";
                    this.msgText = "告。もう一度入浴しますか？";
                    this.playVoice(17);
                    break;
                case 20:
                    this.voiceSound.pause();
                    if (this.isReplay) {
                        this.$router.replace({ name: 'present' });
                    } else {
                        appDom.classList.remove("rally1Bg");
                    }
                    break;
                case 21:
                    this.msgBgColor = "#960000";
                    this.msgName = "";
                    this.msgText = "クリア特典のフォトフレームを\n手に入れた！";

                    this.$store.commit("clearRally5");
                    break;
                case 22:
                    this.goNext();
                // break;
                default:
                    this.msgBgColor = "#000000";
                    this.msgName = "";
                    this.msgText = "";
                    break;
            }
            this.lockStoryTimer = setTimeout(() => {
                this.isLockStory = false;
            }, nextTiming)

        },
        goNext() {
            this.$router.replace({ name: 'top' });
        },
        eventConfOk() {
            this.dialogConf = false;

            this.voiceSound.play();
            this.voiceSound.pause();

            if (this.isReplay || this.nowStory == -1) {
                // this.nowStory = this.$store.getters.viewStoryNum;
                // if (this.nowStory == 0) {
                this.nowStory = this.nextStory;
                // }
                this.$store.commit("setStoryNum", this.nowStory);
                this.storyEvent();
            } else {
                let movie = this.$refs.endingMovie;

                movie.classList.add("endingMovie");
                movie.muted = false
                movie.volume = 1;
                movie.play();
                // movie.pause();
                movie.addEventListener("ended", () => {
                    this.storyNext();
                });

                let skip = this.$refs.movieSkip;
                skip.classList.add("endingMovie");

                // setTimeout(() => {
                //     // movie.play();
                //     // this.checkVideoTime();

                //     movie.classList.remove("endingMovie");
                //     movie.classList.add("skipVisible");

                //     skip.classList.remove("endingMovie");
                //     skip.classList.add("skipVisible");
                // }, 3000);
            }


        },
        goSkip() {
            this.viewYesNo = true;
            let movie = this.$refs.endingMovie;
            // movie.currentTime = movie.duration;
            movie.pause();
            this.storyNext();
        },
        playVoice(num) {
            console.log("playVoice", num);
            clearTimeout(this.voicePauseTimer);
            this.voiceSound.currentTime = num * 10;
            this.voiceSound.play();
            this.voicePauseTimer = setTimeout(() => {
                console.log("stopVoice", num);
                this.voiceSound.pause();
            }, 9000);
        }
    },
    computed: {
    },
    watch: {

    }
};
</script>

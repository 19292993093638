<template>
    <v-container class="baseStyle">
        <v-row>
            <v-col cols="12" class="ma-0 pa-0">
                <tegata v-if="nowRallyNum <= 5" :name="$store.getters.userName" :animation="false" :stamp="nowRallyNum"
                    :ura="true" :receive="false">
                </tegata>
                <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
                    height="100%" viewBox="0 0 427 730">
                    <g transform="translate(0 0)">
                        <image width="427" height="730" xlink:href="/images/tegata/bg.jpg"></image>
                    </g>
                    <g transform="translate(20 85)">
                        <image width="396" height="472" xlink:href="/images/rally5/meister.png"></image>
                    </g>
                </svg>
            </v-col>
        </v-row>


        <div class="msgArea">
            <div @click="goTop">
                <MessageWindow :name="msgName" :text="msgText" :lock="isLockStory" :bgcolor="msgBgColor">
                </MessageWindow>
            </div>
        </div>

    </v-container>
</template>

<style scoped>
.baseStyle {
    position: relative;
    padding: 0 !important;
    width: 100%;
    max-width: 480px;
}

.msgArea {
    position: fixed;
    width: 100%;
    padding: 0 3vw;
    bottom: 30px;
    left: 0;
    text-align: center;
    z-index: 1;
}

.msgArea>div {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
}
</style>
<script>
import MessageWindow from "./components/MessageWindow";
import Tegata from "./components/Tegata";

export default {
    components: {
        MessageWindow,
        Tegata
    },
    data: () => ({
        nowRallyNum: 0,
        msgName: '',
        msgText: '',
        msgBgColor: '#960000',
        lockStoryTimer: undefined,
        isLockStory: false,
    }),
    mounted: async function () {
        try {
            document.getElementsByTagName("html")[0].classList.add("noScroll")
        } catch (error) { }

        let appDom = document.getElementById("app");
        appDom.classList.add("tegataBg");
        let nowRally = this.$store.getters.nowPlayRally;
        this.nowRallyNum = Number(nowRally.num);

        if (this.nowRallyNum == 0) {
            this.$router.replace({ name: 'top' });
        }
        if (nowRally.allclear || this.nowRallyNum == 5 && nowRally.data[1]) {
            this.nowRallyNum = 9;
            this.msgText = "ユネッサン・テンペストを熟知した証\nユネッサン・マイスターだ！";
        } else if (this.nowRallyNum == 5 && nowRally.data[0]) {
            this.msgText = "スタンプが５つ押された\n湯めぐり手形だ！";
            this.nowRallyNum = 5;
        } else if (this.nowRallyNum <= 5) {
            this.nowRallyNum--;
            this.msgText = "スタンプが" + ["１", "２", "３", "４", "５"][this.nowRallyNum - 1] + "つ押された\n湯めぐり手形だ！";
        }
    },
    destroyed: function () {
        try {
            document.getElementsByTagName("html")[0].classList.remove("noScroll")
        } catch (error) { }

        let appDom = document.getElementById("app");
        appDom.classList.remove("tegataBg");
    },
    methods: {
        goTop() {
            this.$router.replace({ name: 'top' });
        },
    },
    computed: {
    },
    watch: {
    }
};
</script>

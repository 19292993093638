import Vue from 'vue'
import VueRouter from 'vue-router'

import LoadingView from '../views/LoadingView.vue'
import StartView from '../views/StartView.vue'
import IntroView from '../views/IntroView.vue'
import TopView from '../views/TopView.vue'
import RallyMap from '../views/RallyMap.vue'
import Rally1Main from '../views/Rally1Main.vue'
import Rally2Next from '../views/Rally2Next.vue'
import Rally2Main from '../views/Rally2Main.vue'
import Rally3Main from '../views/Rally3Main.vue'
import Rally4Main from '../views/Rally4Main.vue'
import Rally4Intro from '../views/Rally4Intro.vue'
import Rally4Next from '../views/Rally4Next.vue'
import Rally5Main from '../views/Rally5Main.vue'
import TegataView from '../views/TegataView.vue'
import PresentView from '../views/PresentView.vue'
import ErrorView from '../views/ErrorView.vue'
import MaintenanceView from '../views/MaintenanceView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'loading',
    component: LoadingView
  },
  {
    path: '/start',
    name: 'start',
    component: StartView
  },
  {
    path: '/intro',
    name: 'intro',
    component: IntroView
  },
  {
    path: '/top',
    name: 'top',
    component: TopView
  },
  {
    path: '/rallymap',
    name: 'rallymap',
    component: RallyMap
  },
  {
    path: '/rally1',
    name: 'rally1',
    component: Rally1Main
  },
  {
    path: '/rally2',
    name: 'rally2',
    component: Rally2Main
  },
  {
    path: '/rally2next',
    name: 'rally2next',
    component: Rally2Next
  },
  {
    path: '/rally3',
    name: 'rally3',
    component: Rally3Main
  },
  {
    path: '/rally4',
    name: 'rally4',
    component: Rally4Main
  },
  {
    path: '/rally4intro',
    name: 'rally4intro',
    component: Rally4Intro
  },
  {
    path: '/rally4next',
    name: 'rally4next',
    component: Rally4Next
  },
  {
    path: '/rally5',
    name: 'rally5',
    component: Rally5Main
  },
  {
    path: '/tegata',
    name: 'tegata',
    component: TegataView
  },
  {
    path: '/present',
    name: 'present',
    component: PresentView
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: MaintenanceView
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorView
  },
  {
    path: '*',
    name: 'allerror',
    component: ErrorView
  }
]

const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
